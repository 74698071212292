// import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { PointDataFrameDef } from '../../model/definitions/PointDataFrameDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import {
  SymbolLayerDef,
  SymbolLayerPointDef,
  SymbolSourceType,
} from '../../model/definitions/SymbolLayerDef';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { store } from '../../store/store';
import { _kc } from '../auth/KeycloakService';
import { MessageTypeEnum } from './WeatherDataLoader.worker';
import {
  FrameLoadingResult,
  FrameLoadingStatus,
  FramePoints,
  PointWithValue,
} from './WeatherDataLoaderTypes';

let loadFirstTime = true;

export const getCacheKey = (
  frameId: string,
  layerType: string,
  visualisationType: VisualisationTypeEnum | '' = '',
) => {
  return `${layerType}/${frameId}${visualisationType ?? ''}`;
};
export class WeatherDataLoader {
  private static instance: WeatherDataLoader;
  private workerInstance: Worker;

  // The loading queue holds all frames for which loading has been requested
  // It should contain all the info worker needs to load frames
  private static queue: any[];

  private static weatherDataCache: Record<string, FrameLoadingResult> = {};
  private static weatherPointsCache: Record<string, FramePoints> = {};

  public static getByFrameId(
    frameId: string,
    layerType: string,
    visualisationType: VisualisationTypeEnum | '' = '',
  ): FrameLoadingResult {
    return WeatherDataLoader.weatherDataCache[getCacheKey(frameId, layerType, visualisationType)];

    /**Here there is some loop of 10s of thousands of calls */
  }

  public static getByFrameIdWeatherPoints(frameId: string, layerType: string) {
    return WeatherDataLoader.weatherPointsCache[getCacheKey(frameId, layerType)];
  }

  static setPoints(layerId: string, points: SymbolLayerPointDef[]) {
    const frame = Object.values(WeatherDataLoader.weatherPointsCache).find(
      (frame) => frame.layerId === layerId,
    );
    if (frame && frame.points) {
      const framePoints = frame.points?.filter((framePoint) =>
        points.some((point) => point.locationId === framePoint.locationId),
      );
      frame.points = framePoints;
    }
  }

  public static getInstance(): WeatherDataLoader {
    if (!WeatherDataLoader.instance) {
      WeatherDataLoader.instance = new WeatherDataLoader();
    }

    return WeatherDataLoader.instance;
  }

  public static getCacheKey = (
    frameId: string,
    layerType: string,
    visualisationType: VisualisationTypeEnum | '' = '',
  ) => getCacheKey(frameId, layerType, visualisationType);
  // private workerInstance: Worker;

  getWorkerInstance(): Worker {
    if (!this.workerInstance) {
      this.workerInstance = new Worker(new URL('./WeatherDataLoader.worker.ts', import.meta.url));
    }
    return this.workerInstance;
    // return new Worker(new URL('./WeatherDataLoader.worker.ts', import.meta.url));
  }

  loadWeatherData(
    projectId: string,
    layers: Array<RadarMapLayer | GribMapLayer | SatelliteMapLayer | SymbolLayerDef>,
    mapPanel: MapPanelDef,
    firstFrameOnly: boolean,
    onFrameLoadCallback?: () => void,
  ): void {
    const worker = this.getWorkerInstance();

    worker.postMessage({
      type: MessageTypeEnum.token,
      payload: { token: _kc.token, userId: _kc.idTokenParsed?.email },
    });

    const framesToLoadPerLayer: Record<string, string[]> = {};
    const addFrameToLoad = (frameId: string, layerId: string) => {
      if (!framesToLoadPerLayer[layerId]) {
        framesToLoadPerLayer[layerId] = [];
      }
      framesToLoadPerLayer[layerId].push(frameId);
    };

    layers.forEach((layer) => {
      const layerType = layer.layerType;
      if (
        layer.layerType === 'SYMBOL' &&
        // @ts-ignore
        layer.symbolSource.sourceType === SymbolSourceType.PointData
      ) {
        // @ts-ignore
        const framesToLoad = layer.symbolSource.pointDataFrames
          ? // @ts-ignore
            layer.symbolSource.pointDataFrames.map((frame: PointDataFrameDef) => frame.dateString)
          : [];
        for (let i = 0; i < framesToLoad.length; i++) {
          if (firstFrameOnly && i > 0) return;
          const frameId = framesToLoad[i];
          const visualisationType = layer.layerSetup.visualisationType || '';
          if (!WeatherDataLoader.weatherPointsCache[getCacheKey(frameId, layerType)]) {
            WeatherDataLoader.weatherPointsCache[getCacheKey(frameId, layerType)] = {
              frameId,
              layerId: mapPanel.id,
              points: [],
            };
            loadFirstTime = true;
          }

          if (
            !WeatherDataLoader.weatherDataCache[getCacheKey(frameId, layerType, visualisationType)]
          ) {
            WeatherDataLoader.weatherDataCache[getCacheKey(frameId, layerType, visualisationType)] =
              {
                status: FrameLoadingStatus.Loading,
                frameId,
                layerId: mapPanel.id,
                layerType,
                visualisationType,
              };
            addFrameToLoad(frameId, layer.id);
          }
        }
        return;
      }
      const framesToLoad = layer.dataFrames.map((frame) => frame.frameId);
      for (let i = 0; i < framesToLoad.length; i++) {
        if (firstFrameOnly && i > 0) return;
        const frameId = framesToLoad[i];
        const visualisationType = layer.layerSetup.visualisationType || '';
        if (!WeatherDataLoader.weatherPointsCache[getCacheKey(frameId, layerType)]) {
          WeatherDataLoader.weatherPointsCache[getCacheKey(frameId, layerType)] = {
            frameId,
            layerId: mapPanel.id,
            points: [],
          };
          loadFirstTime = true;
        }
        if (
          !WeatherDataLoader.weatherDataCache[getCacheKey(frameId, layerType, visualisationType)]
        ) {
          WeatherDataLoader.weatherDataCache[getCacheKey(frameId, layerType, visualisationType)] = {
            status: FrameLoadingStatus.Loading,
            frameId,
            layerId: mapPanel.id,
            layerType,
            visualisationType,
          };
          addFrameToLoad(frameId, layer.id);
        }
      }
    });

    const reduxState = store.getState();

    /**When user clicks on new point before previous request is done it get's into race condition and request loops */
    if (reduxState.active.symbolEditingLayerId)
      worker.postMessage({ type: MessageTypeEnum.abortinitial });
    if (reduxState.active.reloadPoints) {
      worker.postMessage({
        type: MessageTypeEnum.data,
        payload: {
          layers,
          mapPanel,
          firstFrameOnly,
          loadFirstTime,
          projectId,
          framesToLoadPerLayer,
        },
      });
    }

    worker.onmessage = (e) => {
      this.handleWorkerMessage(e, firstFrameOnly);
      loadFirstTime = false;
      if (onFrameLoadCallback) {
        onFrameLoadCallback();
      }
    };
  }

  handleWorkerMessage(event: MessageEvent<FrameLoadingResult>, firstFrameOnly: boolean): void {
    const cacheKey = getCacheKey(
      event.data.frameId,
      event.data.layerType,
      event.data.visualisationType,
    );
    const uniquePointsMap = new Map();
    if (event.data.symbolData) {
      if (loadFirstTime || !firstFrameOnly) {
        if (event.data.symbolData?.points) {
          event.data.symbolData.points.forEach((point) => {
            const key = `${point.lat}_${point.lon}`;
            uniquePointsMap.set(key, point);
          });
        }
      } else {
        if (WeatherDataLoader.weatherPointsCache[cacheKey]?.points) {
          WeatherDataLoader.weatherPointsCache[cacheKey].points?.forEach((point) => {
            const key = `${point.lat}_${point.lon}`;
            uniquePointsMap.set(key, point);
          });
        }
        if (event.data.symbolData?.points) {
          event.data.symbolData.points.forEach((point) => {
            const key = `${point.lat}_${point.lon}`;
            uniquePointsMap.set(key, point);
          });
        }
      }
      const points: PointWithValue[] = Array.from(uniquePointsMap.values());

      WeatherDataLoader.weatherPointsCache[cacheKey] = {
        frameId: event.data.frameId,
        layerId: event.data.layerId,
        points,
      };
      WeatherDataLoader.weatherDataCache[cacheKey] = {
        ...event.data,
        symbolData: {
          ...event.data.symbolData,
          points,
        },
      };
    } else {
      WeatherDataLoader.weatherDataCache[cacheKey] = event.data;
      // WeatherDataLoader.weatherPointsCache[cacheKey] = event.data;
    }

    if (WeatherDataLoader.weatherDataCache[cacheKey].data?.image) {
      const img = new Image();
      img.src = WeatherDataLoader.weatherDataCache[cacheKey].data!.image;
      img.onload = () => {
        WeatherDataLoader.weatherDataCache[cacheKey].data!.imageBlob = img;
        WeatherDataLoader.weatherDataCache[cacheKey].status = FrameLoadingStatus.Success;
      };
    }
  }

  static getLoadingState(): Record<FrameLoadingStatus, number> {
    const loadingState: Record<FrameLoadingStatus, number> = {
      [FrameLoadingStatus.Loading]: 0,
      [FrameLoadingStatus.Error]: 0,
      [FrameLoadingStatus.Success]: 0,
    };

    Object.values(WeatherDataLoader.weatherDataCache).forEach((frame) => {
      loadingState[frame.status]++;
    });

    return loadingState;
  }

  static resetLoadingState(): void {
    WeatherDataLoader.weatherDataCache = {};
  }

  static areAllFramesLoaded() {
    const loadingState = WeatherDataLoader.getLoadingState();
    return loadingState[FrameLoadingStatus.Loading] === 0;
  }

  static getFramesState() {
    const framesState: Record<string, FrameLoadingStatus> = {};
    for (const [frameId, frame] of Object.entries(WeatherDataLoader.weatherDataCache)) {
      framesState[frameId] = frame.status;
    }
    return framesState;
  }

  static clearCache() {
    for (const [frameId, frame] of Object.entries(WeatherDataLoader.weatherDataCache)) {
      if (frame.data && frame.data.image) {
        URL.revokeObjectURL(frame.data.image);
        URL.revokeObjectURL(frame.data.coordinates);
      }
      delete WeatherDataLoader.weatherDataCache[frameId];
      // delete WeatherDataLoader.weatherPointsCache[frameId];
    }
  }

  // static clearSelectedFramesCache(layerType: string, framesIds: DataFrameDef[]) {
  //   framesIds.forEach((f) => {
  //     const id = getCacheKey(f.frameId, layerType);
  //     const frame = WeatherDataLoader.weatherDataCache[id];
  //     if (frame) {
  //       if (frame.data && frame.data.image) {
  //         URL.revokeObjectURL(frame.data.image);
  //         URL.revokeObjectURL(frame.data.coordinates);
  //       }
  //       delete WeatherDataLoader.weatherDataCache[id];
  //     }
  //   });
  // }
  /**No used if use set also layerType as cache key not layerId */
  // static setOverride(
  //   layerId: string,
  //   frameId: string,
  //   lat: number,
  //   lon: number,
  //   newValue: number[],
  //   oldValue: number[],
  //   timestamp?: number,
  //   userId?: string,
  // ) {
  //   const id = getCacheKey(frameId, layerId);
  //   const frame = WeatherDataLoader.weatherDataCache[id];
  //   frame.symbolData?.points.forEach((p) => {
  //     if (p.lat == lat && p.lon == lon) {
  //       p.val = newValue;
  //       p.old_val = oldValue;
  //       p.updated_at = timestamp;
  //       p.user_id = userId;
  //     }
  //   });
  // }

  // static setOverrideByLocation(
  //   layerId: string,
  //   frameId: string,
  //   locationId: string,
  //   newValue: number[],
  //   oldValue: number[],
  //   timestamp?: number,
  //   userId?: string,
  //   overrideId?: number,
  // ) {
  //   const id = getCacheKey(frameId, layerId);
  //   const frame = WeatherDataLoader.weatherDataCache[id];
  //   frame.symbolData?.points.forEach((p) => {
  //     if (p.locationId == locationId) {
  //       p.val = newValue;
  //       p.old_val = oldValue;
  //       p.timestamp = timestamp;
  //       p.userId = userId;
  //       p.overrideId = overrideId;
  //     }
  //   });
  // }
}
