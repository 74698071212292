// This class is meant to be used from a web worker

import { GeoLocationDef } from '../../model/definitions/GeoLoactionDef';
import { SymbolLayerPointDef, SymbolPointType } from '../../model/definitions/SymbolLayerDef';
import { ModelPointOverrideDTO } from '../../model/DTO/ModelPointOverrideDTO';
import {
  OverrideParticipantDTO,
  OverrideParticipantPointDTO,
} from '../../model/DTO/OverrideParticipantDTO';
import { PointDataOverrideDTO } from '../../model/DTO/PointDataOverrideDTO';
import { FilteringTypeEnum, PostProcessingTypeEnum } from '../../model/enums/FilteringTypeEnum';
import { WeatherDataResponse } from './WeatherDataHttpTypes';

export class WeatherDataHttpClient {
  // TODO: make sure access token is not exposed in the production build
  private static ACCESS_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQm9nZGFuIiwiYm9ybiI6IjAwOjU0IDE1LjA5LjIwMjIifQ.sZ_70fg4kwqHFBwUDBaT-q25bVsCzq-nN9dgCsY1jXs';

  private static ACCESS_TOKEN_STORE = '';

  private static USER_EMAIL = '';

  private static API_URL = `${process.env.REACT_APP_WEATHER_DATA_URL}`;

  private static POINT_DATA_API_URL = `${process.env.REACT_APP_POINT_API_BASE_URL}`;

  private static instance: WeatherDataHttpClient;

  public static getInstance(): WeatherDataHttpClient {
    if (!WeatherDataHttpClient.instance) {
      WeatherDataHttpClient.instance = new WeatherDataHttpClient();
    }

    return WeatherDataHttpClient.instance;
  }

  public static setAccessToken(token: string) {
    WeatherDataHttpClient.ACCESS_TOKEN_STORE = token;
  }

  public static setUserEmail(email: string) {
    WeatherDataHttpClient.USER_EMAIL = email;
  }

  public async getIsolineData(url: string): Promise<any> {
    const response = await fetch(`${url}?response2wgs84=true`, {
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN_STORE}`,
      },
    });

    return await response.json();
  }

  public async getWeatherData(
    layerType: 'model' | 'radar' | 'satellite' | 'symbol',
    frames: string[],
    topLeft: GeoLocationDef,
    bottomRight: GeoLocationDef,
    projectionString: string,
    unit: string,
    preprocessing: FilteringTypeEnum | null,
    postprocessing: PostProcessingTypeEnum | null,
    embossEffect = 0,
    numberOfIterations = 0,
  ): Promise<WeatherDataResponse> {
    const isNsper = projectionString && projectionString.includes('nsper');

    let preprocessingValue: string = preprocessing ?? '';
    preprocessingValue =
      preprocessing == FilteringTypeEnum.LOW_BANDPASS
        ? `${preprocessing}:${numberOfIterations}`
        : preprocessingValue;

    let postprocessingValue: string = postprocessing ?? '';
    postprocessingValue =
      postprocessing == PostProcessingTypeEnum.EMBOSS
        ? `${postprocessing}:${embossEffect}`
        : postprocessingValue;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      frame_ids: frames.join(','),
      upper_y: topLeft.latitude,
      left_x: topLeft.longitude,
      lower_y: bottomRight.latitude,
      right_x: bottomRight.longitude,
      response_type: 'stream',
      max_points_per_higher_axis: 2048,
      response2wgs84: isNsper,
      projection: projectionString,
      filtering_type: JSON.stringify({
        preprocessing: preprocessingValue, // "median", "gausian", "low_bandpass:x", ""
        postprocessing: postprocessingValue, // "median-post", "emboss:x", ""
      }),
      app: 'CDOT',
    };

    if (unit) {
      params.unit = unit;
    }

    const url = `${WeatherDataHttpClient.API_URL}/datapackage/${layerType}?${new URLSearchParams(
      params,
    ).toString()}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN}`,
      },
    });

    const contentType = response.headers.get('content-type');
    const isZip = contentType == 'application/zip';
    const isJson = contentType == 'application/json';

    return {
      zip: isZip ? await response.blob() : null,
      json: isJson ? await response.json() : null,
    };
  }

  public async getSymbols(
    projectId: string,
    frames: string[],
    points: SymbolLayerPointDef[],
    unit: string,
    parameter: string,
  ) {
    const params: any = {
      frame_ids: frames.join(','),
      points,
      override_participant: {
        project_id: projectId,
        user_id: WeatherDataHttpClient.USER_EMAIL,
      },
    };

    if (unit) {
      params.unit = unit;
    }

    const url = `${WeatherDataHttpClient.API_URL}/symbol/model/${
      parameter === 'WeatherType' ? 'weather-data' : 'search-points'
    }`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  }

  public async getPointDataInitial(
    projectId: string,
    parameter: string,
    timestamp: number,
    points: SymbolLayerPointDef[],
    boundingBox: { points: GeoLocationDef[] },
    pointType: SymbolPointType,
    dataProductId: string,
    signal: AbortSignal,
  ) {
    const params: any = {
      boundingBox,
      points: points.map((p) => {
        return { latitude: p.lat, longitude: p.lon };
      }),
      parameter,
      timestamp,
      overrideParticipant: {
        projectId,
        userId: WeatherDataHttpClient.USER_EMAIL,
      },
      dataProductId,
    };
    const lowerCasePointType = pointType.toLowerCase();
    const url = `${
      WeatherDataHttpClient.POINT_DATA_API_URL
    }/api/${lowerCasePointType}/symbol-layer/${
      pointType === SymbolPointType.Forecast ? `${lowerCasePointType}s` : lowerCasePointType
    }-initial`;

    /**Abort will throw error catch it in handler */

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN_STORE}`,
        'Content-Type': 'application/json',
      },
      signal,
    });

    return await response.json();
  }

  public async getPointData(
    projectId: string,
    parameter: string,
    timestamp: number,
    points: SymbolLayerPointDef[],
    boundingBox: { points: GeoLocationDef[] },
    pointType: SymbolPointType,
    dataProductId: string,
  ) {
    const params: any = {
      boundingBox,
      stations: points.map((p) => {
        return { point: { latitude: p.lat, longitude: p.lon }, locationId: p.locationId };
      }),
      parameter,
      timestamp,
      overrideParticipant: {
        projectId,
      },
      dataProductId,
    };
    const lowerCasePointType = pointType.toLowerCase();
    const url = `${
      WeatherDataHttpClient.POINT_DATA_API_URL
    }/api/${lowerCasePointType}/symbol-layer/${
      pointType === SymbolPointType.Forecast ? `${lowerCasePointType}s` : lowerCasePointType
    }`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN_STORE}`,
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  }

  public async overrideModelData(data: {
    frameId: string;
    pointOverride: ModelPointOverrideDTO;
    overrideParticipant: OverrideParticipantDTO;
  }) {
    const { frameId, pointOverride, overrideParticipant } = data;
    const params = {
      frames: [
        {
          frame_id: frameId,
          points: [pointOverride],
        },
      ],
      override_participant: overrideParticipant,
    };

    const url = `${WeatherDataHttpClient.API_URL}/symbol/model/override-points`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  }

  public async overridePointData(data: {
    overrideParticipant: OverrideParticipantPointDTO;
    overrides: PointDataOverrideDTO[];
    pointType: SymbolPointType;
    dataProductId: string;
  }) {
    const { overrideParticipant, overrides, pointType, dataProductId } = data;
    const params = {
      overrideParticipant,
      overrides,
      dataProductId,
    };

    const url = `${
      WeatherDataHttpClient.POINT_DATA_API_URL
    }/api/${pointType.toLowerCase()}/symbol-layer/set-overrides`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN_STORE}`,
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  }

  public async revertPointData(data: { id: number; userId: string; pointType: SymbolPointType }) {
    const { id, userId, pointType } = data;
    const params = {
      id,
      userId,
    };
    const lowerCasePointType = pointType.toLowerCase();
    const url = `${WeatherDataHttpClient.POINT_DATA_API_URL}/api/${lowerCasePointType}/symbol-layer/revert-overrides`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN_STORE}`,
        'Content-Type': 'application/json',
      },
    });
    return response.status;
  }

  public async revertModelData(data: {
    frame_id: string;
    points: { lat: number; lon: number }[];
    override_participant: OverrideParticipantDTO;
  }) {
    const url = `${WeatherDataHttpClient.API_URL}/symbol/model/revert_overrides`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${WeatherDataHttpClient.ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
    });
    return response.status;
  }
}
