import { AnyAction } from '@reduxjs/toolkit';
import { distance as turfDistance, point } from '@turf/turf';
import { chunk, cloneDeep, isNull } from 'lodash';
import { Feature, Map, Map as OlMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
// import { EventsKey } from 'ol/events';
import { altKeyOnly, click, shiftKeyOnly } from 'ol/events/condition';
import { FeatureLike } from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';
import { Geometry } from 'ol/geom';
import {
  DoubleClickZoom,
  DragZoom,
  Interaction,
  KeyboardZoom,
  Modify,
  MouseWheelZoom,
  PinchZoom,
  Select,
  Snap,
  Translate,
} from 'ol/interaction';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorTileLayer from 'ol/layer/VectorTile';
import { fromLonLat, ProjectionLike, toLonLat, transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { State } from 'ol/render';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import { Circle, Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';
// import CircleStyle from 'ol/style/Circle';
import ImageStyle from 'ol/style/Image';
import { StyleLike } from 'ol/style/Style';
// import TileState from 'ol/TileState';
import proj4 from 'proj4';
import React, { Dispatch } from 'react';

import { SceneSimple } from '../../components/timeline/Timeline';
// import { axiosInstance } from '../../core/api/axiosInstance';
// import { getWindFrames } from '../../core/api/mapLayers/LayersAPI';
import { getSnapshot } from '../../core/api/TemplatesAPI';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
import { singleColorOpacity } from '../../helpers/convertOpacity';
import { getFrameForMS } from '../../helpers/getFpsFromExportFormat';
import { getMedianDifferenceFromTimestamp, getNormalisedFrames } from '../../helpers/math';
import { parseScenes } from '../../helpers/timelineUtil';
import {
  MAX_FRAMES_PER_LAYER,
  MAX_FULLSCREEN_HEIGHT,
  NO_THUMBNAIL_URL,
  STUDIO_PANEL_HEIGHT,
} from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { CustomVectorLayer } from '../../model/definitions/CustomVectorLayer';
import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { DrawingDef } from '../../model/definitions/DrawingDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { SceneDef } from '../../model/definitions/SceneDef';
import { SymbolLayerDef } from '../../model/definitions/SymbolLayerDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { defaultStyle, VectorMapLayer } from '../../model/definitions/VecorMapLayer';
import { WeatherDataMapLayerSetup } from '../../model/definitions/WeatherDataMapLayerSetup';
import { WeatherDataSpaceDef } from '../../model/definitions/WeatherDataSpaceDef';
import { WeatherGeoPosterDef } from '../../model/definitions/WeatherGeoPosterDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { SnapshotRequestDTO } from '../../model/DTO/SnapshotRequestDTO';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { DrawingTypeEnum, DrawingTypeExternalEnum } from '../../model/enums/DrawingTypeEnum';
import { FrontTypeEnum } from '../../model/enums/FrontTypeEnum';
import { MapLayersEnum } from '../../model/enums/MapLayersEnum';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { GlobalPlayerControl } from '../../pages/playground/GlobalPlayerControl';
// import { MapLayersEnum } from '../../model/enums/MapLayersEnum';
import { PlayContext } from '../../pages/playground/playerContext/PlayerContext';
import { DrawType, refreshActiveDrawEdit, setActiveDraw } from '../../store/slices/active-slice';
import { transformPercentToAbsolute } from '../canvasElements/utils';
import { getRgba } from '../palette/utils';
// import { setActiveDrawEdit, setElement } from '../../store/slices/active-slice';
import {
  cleanDuplcateCoordinatesForBezierCurve,
  getSmoothLineString,
  getStyleForCircle,
  getStyleForImage,
  getTranslatedCoordinates,
  styleForFronts,
  styleFunctionForArrow,
  styleFunctionForBasicShapes,
} from './drawHeplers';
import { GVNSP_MASK_ZINDEX } from './MapElementBaseMerc';
// import { FrameLoadingStatus, LayerLoadingCache } from './LayerLoadingCache';

export const END_TIME_WINDOW = 10;

/**
 * Number in milliseconds - all frames that have a
 * start time lower than this will be prerendered
 */
// const PRERENDER_MS_FROM_START = 300;
/**
 * Number in milliseconds - how much time in advance
 * from current playing position should layer rendering begin
 */
// @ts-ignore
window.PRERENDER_MS_LIVE = 300;
const SECONDS_PER_HOUR = 3600;

export function registerAllUtmProjections(reg: typeof register, pr4: typeof proj4) {
  for (let zone = 1; zone <= 60; zone++) {
    const code = 'EPSG:' + (32600 + zone);
    pr4.defs(code, '+proj=utm +zone=' + zone + ' +ellps=WGS84 +datum=WGS84 +units=m +no_defs');
    reg(pr4);
  }
}

export function getProjectionStringForUtm(zone: number) {
  return '+proj=utm +zone=' + zone + ' +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
}

export function utmZoneFromLonLat(lonLat: [number, number]) {
  const [lon, lat] = lonLat;
  // Norway & Svalbard
  if (lat > 55 && lat < 64 && lon > 2 && lon < 6) {
    return 32;
  }
  if (lat > 71 && lon >= 6 && lon < 9) {
    return 31;
  }
  if (lat > 71 && ((lon >= 9 && lon < 12) || (lon >= 18 && lon < 21))) {
    return 33;
  }
  if (lat > 71 && ((lon >= 21 && lon < 24) || (lon >= 30 && lon < 33))) {
    return 35;
  }
  // Rest of the world
  if (lon >= -180 && lon <= 180) {
    return (Math.floor((lon + 180) / 6) % 60) + 1;
  }
  return 30;
}

export function resolutionToZoom(resolution: number) {
  return Math.log2(156543.03390625) - Math.log2(resolution);
}

export function zoomToResolution(zoom: number) {
  return Math.pow(2, Math.log2(156543.03390625) - zoom);
}

export function getKilometersShownOnMap(aspectRatio: [number, number], zoom: number) {
  const resolution = zoomToResolution(zoom);
  const mapWidth = MAX_FULLSCREEN_HEIGHT * (aspectRatio[0] / aspectRatio[1]);
  const kmShown = (mapWidth * resolution) / 1000;
  return kmShown < 1 ? 1 : Math.floor(kmShown);
}

export function getZoomForKilometersShownOnMap(aspectRatio: [number, number], kilometers: number) {
  const mapWidth = MAX_FULLSCREEN_HEIGHT * (aspectRatio[0] / aspectRatio[1]);
  const resolution = (kilometers * 1000) / mapWidth;
  return resolutionToZoom(resolution);
}

export function getFeatureById(featureId: string, layer: VectorLayer<VectorSource<Geometry>>) {
  const features = layer?.getSource()?.getFeatures();
  return features?.find((f) => f.getProperties().featureId === featureId);
}

export function zoomToZoomByRatio(initialZoom: number, ratio: number) {
  const initialResolution = zoomToResolution(initialZoom);
  const newResolution = initialResolution * ratio;
  return resolutionToZoom(newResolution);
}

function isFromErroredTimeControl(f: DataFrameDef & { startTime: number; endTime: number }) {
  return isNaN(f.startTime) || isNaN(f.endTime);
}

export function isTimeInLayersRange(currentTime: number, layersTimeControls: TimeControlDef[]) {
  if (!layersTimeControls?.length) return false;
  const { startMS, endMS } = layersTimeControls[0];
  return startMS <= currentTime && endMS >= currentTime;
}

export function checkShouldRenderFrame(
  currentTime: number,
  f: DataFrameDef & { startTime: number; endTime: number },
  layersTimeControls: TimeControlDef[],
) {
  return (
    ((currentTime > f.startTime || (currentTime === 0 && f.startTime === 0)) &&
      currentTime <= f.endTime) ||
    /**Workaround when only frame startTime and endTime is NaN, show only frame if it's in total layers time range */
    (isFromErroredTimeControl(f) && isTimeInLayersRange(currentTime, layersTimeControls))
  );
}

const PRERENDER_MS_FROM_START = 300;
function checkShouldPrerenderFrame(
  currentTime: number,
  f: DataFrameDef & { startTime: number; endTime: number },
  skips: SkipTimeDef[],
  isSequence: boolean,
) {
  return (
    // @ts-ignore
    (getPrerenderTimeWithSkips(currentTime, skips, isSequence) > f.startTime &&
      currentTime <= f.endTime) ||
    isFromErroredTimeControl(f) ||
    // prerender frames at the beginning so that they are visible when restarted
    (currentTime < PRERENDER_MS_FROM_START && f.startTime < PRERENDER_MS_FROM_START)
  );
}

function getPrerenderTimeWithSkips(currentTime: number, skips: SkipTimeDef[], isSequence: boolean) {
  // @ts-ignore
  let prerenderTime = (currentTime + window.PRERENDER_MS_LIVE) as number;
  if (!skips.length || isSequence) return prerenderTime;
  skips.forEach((sk) => {
    if (
      (sk.startMS > currentTime && sk.startMS < prerenderTime) ||
      // currentTime before it jumps to skip time end once goes over skip start time
      (currentTime >= sk.startMS && currentTime <= sk.endMS)
    ) {
      prerenderTime += sk.endMS - sk.startMS;
    }
  });
  return prerenderTime;
}

export const getDataFromWindow = () => {
  // @ts-ignore
  const gw = (prop, d) =>
    // @ts-ignore
    Object.hasOwn(window, 'initiateSettings') && Object.hasOwn(window.initiateSettings, prop)
      ? // @ts-ignore
        window.initiateSettings[prop]
      : d;

  return {
    itemsPerChunk: gw('itemsPerChunk', 4),
    waitBetweenRequests: gw('waitBetweenRequests', 100),
    waitBetweenChunks: gw('waitBetweenChunks', 0),
    waitForAllRequestsInChunk: gw('waitForAllRequestsInChunk', true),
    tilesRangeMin: gw('tilesRangeMin', 1),
    tilesRangeMax: gw('tilesRangeMax', 10),
    initiateRangeMin: gw('initiateRangeMin', 1),
    initiateRangeMax: gw('initiateRangeMax', 10),
  };
};

export const getRandomInteger = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// const getWindStyleFunction = (
//   color: string,
//   zoom: number | undefined,
//   arrowsEnabled: boolean,
//   arrowSize: number,
// ) => {
//   const canvas = document.createElement('canvas');
//   const arrowWidth = Math.floor(arrowSize * 1.5);
//   const arrowHeight = arrowSize;
//   canvas.width = arrowWidth;
//   canvas.height = arrowHeight;
//   const ctx = canvas.getContext('2d');
//   if (!ctx) return;

//   ctx.beginPath();
//   ctx.moveTo(0, 0);
//   ctx.lineTo(arrowWidth, Math.floor(arrowHeight / 2));
//   ctx.lineTo(0, arrowHeight);
//   ctx.fillStyle = color;
//   ctx.fill();

//   const arrowUrl = canvas.toDataURL();

//   let arrowDensity = 100;
//   if (zoom) {
//     if (zoom > 2) {
//       arrowDensity = 90;
//     }
//     if (zoom > 3) {
//       arrowDensity = 80;
//     }
//     if (zoom > 4) {
//       arrowDensity = 60;
//     }
//     if (zoom > 5) {
//       arrowDensity = 40;
//     }
//     if (zoom > 6) {
//       arrowDensity = 30;
//     }
//     if (zoom > 7) {
//       arrowDensity = 15;
//     }
//     if (zoom > 8) {
//       arrowDensity = 5;
//     }
//     if (zoom > 10) {
//       arrowDensity = 1;
//     }
//   }

//   let i = 0;
//   return function (feature: any) {
//     const geometry = feature.getGeometry();

//     const styles = [
//       // linestring
//       new Style({
//         stroke: new Stroke({
//           color,
//           width: 1,
//         }),
//       }),
//     ];

//     if (!geometry) return styles;

//     if (!arrowsEnabled) return styles;

//     // @ts-ignore
//     geometry.forEachSegment(function (start, end) {
//       if (i % arrowDensity === 0) {
//         const dx = end[0] - start[0];
//         const dy = end[1] - start[1];
//         const rotation = Math.atan2(dy, dx);

//         styles.push(
//           new Style({
//             geometry: new Point(end),
//             image: new Icon({
//               src: arrowUrl,
//               anchor: [0.75, 0.5],
//               rotateWithView: false,
//               rotation: -rotation,
//             }),
//           }),
//         );
//       }
//       i++;
//     });

//     return styles;
//   };
// };

export const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

const layersHash: Record<string, Record<string, { layer: Layer | null; loading: boolean }>> = {};

// Ugly fix to make sure layers aren't considered as loaded
// when you go to dashboard and back
export const clearRenderCache = () => {
  for (const mapId in layersHash) {
    for (const key in layersHash[mapId]) {
      layersHash[mapId][key]?.layer?.dispose();
    }
    delete layersHash[mapId];
  }
};

const FRAME_DATA_CACHE: Record<
  string,
  {
    framesWithTime: { startTime: number; endTime: number; timestamp: number; frameId: string }[];
    finalFrameTimestamp: number;
    finalRenderedFrameTimestamp: number;
  }
> = {};

// TODO use scene hold instead of fixed 3000
export const getSymbolFramesDataCached = (
  isSequence: boolean,
  mapLayer: SymbolLayerDef,
  simpleScene: SceneSimple,
  activeFramerate: number,
) => {
  const firstFrameId = mapLayer.dataFrames[0] ? mapLayer.dataFrames[0].frameId : 0;
  const key = `${simpleScene.startMS}-${activeFramerate}-${mapLayer.id}-${
    mapLayer.symbolSource.points?.map((x) => x.lat + '' + x.lon).join('-') ?? '-'
  }-${mapLayer.timeControls[0].startMS}-${mapLayer.timeControls[0].endMS}-${
    mapLayer.dataFrames.length
  }-${firstFrameId}-${isSequence}-${simpleScene.hold}`;
  if (!FRAME_DATA_CACHE[key]) {
    const heatmapStart = isSequence
      ? mapLayer.timeControls[0].startMS
      : mapLayer.timeControls[0].startMS + simpleScene.startMS;
    const heatmapEnd = isSequence
      ? mapLayer.timeControls[0].endMS
      : mapLayer.timeControls[0].endMS + simpleScene.startMS;

    const step = (heatmapEnd - heatmapStart) / (mapLayer.dataFrames.length - 1);
    const framesWithTime = mapLayer.dataFrames.map((fr, i) => {
      if (mapLayer.dataFrames.length === 1) {
        return {
          ...fr,
          startTime: heatmapStart,
          endTime: heatmapEnd + (simpleScene?.hold ?? 0),
        };
      }
      let startTime = Math.floor(heatmapStart + i * step);
      let endTime = Math.floor(heatmapStart + i * step + step);

      const frameLengthMs = Math.floor(1000 / activeFramerate);

      if (i === mapLayer.dataFrames.length - 2) {
        endTime = heatmapEnd - frameLengthMs + (simpleScene?.hold ?? 0);
      }
      if (i === mapLayer.dataFrames.length - 1) {
        startTime = heatmapEnd - frameLengthMs;
        endTime = heatmapEnd + (simpleScene?.hold ?? 0);
      }

      return {
        ...fr,
        startTime,
        endTime,
      };
    });
    // TODO: dataFrames should be sorted already
    const backwardFrames = [...mapLayer.dataFrames].sort((a, b) => b?.timestamp - a?.timestamp);
    const finalFrameTimestamp = backwardFrames[0] ? backwardFrames[0].timestamp : 0;
    const finalRenderedFrameTimestamp = backwardFrames[1] ? backwardFrames[1]?.timestamp : 0;

    FRAME_DATA_CACHE[key] = { framesWithTime, finalFrameTimestamp, finalRenderedFrameTimestamp };
  }
  return FRAME_DATA_CACHE[key];
};
export const getSymbolPointFramesDataCached = (
  isSequence: boolean,
  mapLayer: SymbolLayerDef,
  simpleScene: SceneSimple,
  activeFramerate: number,
) => {
  if (!mapLayer.symbolSource.pointDataFrames) {
    return { framesWithTime: [] };
  }
  const firstFrameId = mapLayer.symbolSource.pointDataFrames[0]
    ? mapLayer.symbolSource.pointDataFrames[0].dateString
    : 0;
  const key = `${simpleScene.startMS}-${activeFramerate}-${mapLayer.id}-${
    mapLayer.symbolSource.points?.map((x) => x.lat + '' + x.lon).join('-') ?? '-'
  }-${mapLayer.timeControls[0].startMS}-${mapLayer.timeControls[0].endMS}-${
    mapLayer.symbolSource.pointDataFrames.length
  }-${firstFrameId}-${isSequence}-${simpleScene.hold}`;

  if (!FRAME_DATA_CACHE[key]) {
    const heatmapStart = isSequence
      ? mapLayer.timeControls[0].startMS
      : mapLayer.timeControls[0].startMS + simpleScene.startMS;
    const heatmapEnd = isSequence
      ? mapLayer.timeControls[0].endMS
      : mapLayer.timeControls[0].endMS + simpleScene.startMS;
    const length = mapLayer.symbolSource.pointDataFrames.length - 1;
    const step = (heatmapEnd - heatmapStart) / (mapLayer.symbolSource.pointDataFrames.length - 1);
    const framesWithTime = mapLayer.symbolSource.pointDataFrames.map((fr, i) => {
      if (mapLayer.symbolSource.pointDataFrames!.length === 1) {
        return {
          timestamp: fr.startDate,
          frameId: fr.dateString,
          startTime: heatmapStart,
          endTime: heatmapEnd + (simpleScene?.hold ?? 0),
        };
      }
      let startTime = Math.floor(heatmapStart + i * step);
      let endTime = Math.floor(heatmapStart + i * step + step);

      const frameLengthMs = Math.floor(1000 / activeFramerate);

      if (i === mapLayer.symbolSource.pointDataFrames!.length - 2) {
        endTime = heatmapEnd - frameLengthMs + (simpleScene?.hold ?? 0);
      }
      if (i === mapLayer.symbolSource.pointDataFrames!.length - 1) {
        startTime = heatmapEnd - frameLengthMs + (simpleScene?.hold ?? 0);
        endTime = heatmapEnd + (simpleScene.hold ?? 0);
      }

      return {
        timestamp: fr.startDate,
        frameId: fr.dateString,
        startTime,
        endTime: step === length - 1 ? endTime + (simpleScene.hold ?? 0) : endTime,
      };
    });
    // TODO: dataFrames should be sorted already
    const backwardFrames = [...mapLayer.symbolSource.pointDataFrames].sort(
      (a, b) => b?.startDate - a?.startDate,
    );
    const finalFrameTimestamp = backwardFrames[0] ? backwardFrames[0].startDate : 0;
    const finalRenderedFrameTimestamp = backwardFrames[1] ? backwardFrames[1]?.startDate : 0;

    FRAME_DATA_CACHE[key] = { framesWithTime, finalFrameTimestamp, finalRenderedFrameTimestamp };
  }
  return FRAME_DATA_CACHE[key];
};

export const getFramesDataCached = (
  isSequence: boolean,
  mapLayer: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef,
  simpleScene: SceneSimple,
  activeFramerate: number,
) => {
  const firstFrameId = mapLayer.dataFrames[0] ? mapLayer.dataFrames[0].frameId : 0;
  const key = `${simpleScene.startMS}-${activeFramerate}-${mapLayer.id}-${mapLayer.timeControls[0].startMS}-${mapLayer.timeControls[0].endMS}-${mapLayer.dataFrames.length}-${firstFrameId}-${isSequence}-${simpleScene.hold}`;
  if (!FRAME_DATA_CACHE[key]) {
    const heatmapStart = isSequence
      ? mapLayer.timeControls[0].startMS
      : mapLayer.timeControls[0].startMS + simpleScene.startMS;
    let heatmapEnd = isSequence
      ? mapLayer.timeControls[0].endMS
      : mapLayer.timeControls[0].endMS + simpleScene.startMS;
    /**if it's close to end scene (example 9998ms out of 10000) prolong till end */
    if (simpleScene.endMS - heatmapEnd < END_TIME_WINDOW) {
      heatmapEnd = simpleScene.endMS;
    }

    const step = (heatmapEnd - heatmapStart) / (mapLayer.dataFrames.length - 1);
    const framesWithTime = mapLayer.dataFrames.map((fr, i) => {
      if (mapLayer.dataFrames.length === 1) {
        return {
          ...fr,
          startTime: heatmapStart,
          endTime: heatmapEnd + (simpleScene?.hold ?? 0),
        };
      }
      let startTime = Math.floor(heatmapStart + i * step);
      let endTime = Math.floor(heatmapStart + i * step + step);

      const frameLengthMs = Math.floor(1000 / activeFramerate);

      if (i === mapLayer.dataFrames.length - 2) {
        endTime = heatmapEnd - frameLengthMs + (simpleScene?.hold ?? 0);
      }
      if (i === mapLayer.dataFrames.length - 1) {
        startTime = heatmapEnd - frameLengthMs;
        endTime = heatmapEnd + (simpleScene?.hold ?? 0);
      }

      return {
        ...fr,
        startTime,
        endTime,
      };
    });
    // TODO: dataFrames should be sorted already
    const backwardFrames = [...mapLayer.dataFrames].sort((a, b) => b?.timestamp - a?.timestamp);
    const finalFrameTimestamp = backwardFrames[0] ? backwardFrames[0].timestamp : 0;
    const finalRenderedFrameTimestamp = backwardFrames[1] ? backwardFrames[1]?.timestamp : 0;

    FRAME_DATA_CACHE[key] = { framesWithTime, finalFrameTimestamp, finalRenderedFrameTimestamp };
  }
  return FRAME_DATA_CACHE[key];
};

function renderLayer(
  olMap: OlMap,
  mapLayer: GribMapLayer | RadarMapLayer | SatelliteMapLayer,
  layerType: 'satellite' | 'heatmap' | 'radar',
  isSequence: boolean,
  simpleScene: SceneSimple,
  contextValue: PlayContext,
  mapDef: MapPanelDef,
  skips: SkipTimeDef[],
  activeFramerate: number,
) {
  if (!layersHash[mapDef.id]) {
    layersHash[mapDef.id] = {};
  }
  const { framesWithTime } = getFramesDataCached(
    isSequence,
    mapLayer,
    simpleScene,
    activeFramerate,
  );

  // Calculating layer fade opacity
  let fadeOpacity = 1;
  const timeControls = mapLayer.timeControls[0];
  const sceneTimeOffset = isSequence ? 0 : simpleScene.startMS;
  const currentTime = contextValue.time - sceneTimeOffset;
  if (
    timeControls.inAnimationDef === AnimationsEnum.FADE_IN &&
    currentTime < timeControls.startMS + timeControls.inAnimationDuration
  ) {
    fadeOpacity = (currentTime - timeControls.startMS) / timeControls.inAnimationDuration;

    const finalTime = (timeControls.startMS ?? 0) + timeControls.inAnimationDuration;
    const remaining = finalTime - currentTime;
    fadeOpacity = Math.min(
      Math.max((100 - (remaining * 100) / timeControls.inAnimationDuration) / 100, 0),
      1,
    );
  }
  if (
    timeControls.outAnimationDef === AnimationsEnum.FADE_OUT &&
    currentTime > timeControls.endMS - timeControls.outAnimationDuration
  ) {
    let remaining = (timeControls.endMS ?? 0) - currentTime;
    remaining = (remaining * 100) / timeControls.outAnimationDuration / 100;
    fadeOpacity = Math.min(Math.max(remaining, 0), 1);
  }

  const opacity = mapLayer.opacity * fadeOpacity;

  // Commented out because the frames need to load
  // or the loading needs to be setup to support this, otherwise loading is infinite
  // const usedFrames =
  //   isSequence || !skips.length
  //     ? framesWithTime
  //     : framesWithTime.filter((fr) => isFrameOutOfSkippedTime(fr, skips));

  framesWithTime.forEach(async (f) => {
    const frameKey = `${mapLayer.id}-${f.timestamp}-c9layer-${layerType}`;
    if (!layersHash[mapDef.id][frameKey]) {
      const weatherData = WeatherDataLoader.getByFrameId(
        f.frameId,
        mapLayer.layerType,
        mapLayer.layerSetup.visualisationType,
      );
      if (!weatherData || !weatherData.geojson) return;

      layersHash[mapDef.id][frameKey] = { loading: true, layer: null };
      // const isGVNSP = olMap.getView().getProjection().getCode().includes('ESRI:54049');

      const geojsonObject = weatherData.geojson;

      // if (isGVNSP) {
      //   const now = performance.now();
      //   const { projectionCenterLat, projectionCenterLon } = mapDef.properties;

      //   for (let i = 0; i < geojsonObject.features.length; i++) {
      //     for (let j = 0; j < geojsonObject.features[i].geometry.coordinates.length; j++) {
      //       let k = 0;
      //       let newLine = false;
      //       while (k < geojsonObject.features[i].geometry.coordinates[j].length) {
      //         const [lon, lat] = geojsonObject.features[i].geometry.coordinates[j][k];
      //         const dist = distance(
      //           lat,
      //           lon,
      //           Number(projectionCenterLat),
      //           Number(projectionCenterLon),
      //         );
      //         if (dist > 6371) {
      //           // 6371 km is earth radius, if the coordinate is more than R away, remove it
      //           geojsonObject.features[i].geometry.coordinates[j].splice(k, 1);
      //           // we make a new line when we remove coordinates to prevent coordinates being removed in the middle which will cause a straight line
      //           newLine = true;
      //         } else {
      //           // if we're making a new line, take all the rest of the coordinates from this one and add then to the new one
      //           if (newLine) {
      //             const newLineCoordinates =
      //               geojsonObject.features[i].geometry.coordinates[j].splice(k);
      //             geojsonObject.features[i].geometry.coordinates.splice(
      //               j + 1,
      //               0,
      //               newLineCoordinates,
      //             );
      //             newLine = false;
      //           }
      //           k++;
      //         }
      //       }
      //     }
      //   }
      //   console.log('Cutting of isolines took ', performance.now() - now);
      // }
      const source = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject, {
          featureProjection: olMap.getView().getProjection(),
        }),
      });

      // Attempt to get the first color of isoline since all colors must be the same
      const pallet = mapLayer.layerSetup.colorPaletteDef?.colorStops.pallet;
      const isolineWidth = mapLayer.layerSetup.isolineWidth;
      const color = pallet ? getRgba(Object.entries(pallet)[0][1]) : 'red';
      const featureStyle = new Style({
        stroke: new Stroke({
          color: color,
          width: isolineWidth ?? 1,
        }),
      });

      const layer = new VectorLayer({
        source: source,
        className: frameKey,
        opacity: 0,
        visible:
          contextValue.isPlaying != PlaybackEnum.PLAYING || f.startTime < PRERENDER_MS_FROM_START,
        zIndex: mapLayer.zindex,
        style: featureStyle,
      });

      // setupLoadingVectorLayer(layer, mapDef.id, mapLayer.id, f.timestamp);
      olMap.addLayer(layer);
      layersHash[mapDef.id][frameKey] = { layer, loading: false };
    }

    const layerStatus = layersHash[mapDef.id][frameKey];
    if (!layerStatus || layerStatus.loading || !layerStatus.layer) {
      return;
    }
    const layer = layerStatus.layer;

    if (layer.getZIndex() !== mapLayer.zindex) {
      layer.setZIndex(mapLayer.zindex);
    }
    const pallet = mapLayer.layerSetup.colorPaletteDef?.colorStops.pallet;
    const color = pallet ? getRgba(Object.entries(pallet)[0][1]) : 'red';
    if (
      // @ts-ignore
      layer.getStyle().getStroke().getWidth() !== mapLayer.layerSetup.isolineWidth ||
      // @ts-ignore
      layer.getStyle().getStroke().getColor() != color
    ) {
      // @ts-ignore
      layer.setStyle(
        new Style({
          stroke: new Stroke({
            color: color,
            width: mapLayer.layerSetup.isolineWidth,
          }),
        }),
      );
    }

    // if (finalFrameTimestamp === f.timestamp && framesWithTime.length > 1) {
    //   // Final frame is not displayed as a workaround and it's needed
    //   // only to get the timing
    //   layer.setOpacity(0);
    //   layer.setVisible(true);
    //   /**Render frame before final frame until layer end time */
    //   if (
    //     finalRenderedFrameTimestamp &&
    //     checkShouldRenderFrame(contextValue.time, f, mapLayer.timeControls)
    //   ) {
    //     const lastFrameKey = `${mapLayer.id}-${finalRenderedFrameTimestamp}-c9layer-${layerType}`;
    //     const lastLayer = layersHash[mapDef.id][lastFrameKey];
    //     if (lastLayer && !lastLayer.loading && lastLayer.layer) {
    //       lastLayer.layer.setOpacity(1);
    //       lastLayer.layer.setVisible(true);
    //     }
    //   }
    //   return;
    // }

    if (checkShouldRenderFrame(contextValue.time, f, mapLayer.timeControls)) {
      if (layer.getOpacity() !== opacity) {
        layer.setOpacity(opacity);
      }
    } else {
      if (checkShouldPrerenderFrame(contextValue.time, f, skips, isSequence)) {
        if (!layer.getVisible()) {
          layer.setVisible(true);
        }
        if (layer.getOpacity() !== 0) {
          layer.setOpacity(0);
        }
      } else {
        if (layer.getOpacity() !== 0) {
          layer.setOpacity(0);
        }
        layer.setVisible(
          contextValue.isPlaying != PlaybackEnum.PLAYING || f.startTime < PRERENDER_MS_FROM_START,
        );
      }
    }
  });
}

export const renderVectorIsolines = (
  mapDef: MapPanelDef,
  olMap: OlMap,
  contextValue: PlayContext,
  mode: ModeEnum,
  projectToPlay: C9ProjectDef,
  skips: SkipTimeDef[],
  activeFramerate: number,
) => {
  if (!olMap) return;
  // const dt = Date.now();
  const isSequence = mode === ModeEnum.SEQUENCE;
  const parentSceneId = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapDef.id),
  )?.id;
  if (!parentSceneId) return;
  const simpleScenes = parseScenes(projectToPlay);
  const simpleScene = simpleScenes.find((s) => s.id === parentSceneId)!;

  const enabledHeatmapGribs = mapDef.wdSpace[0].gribMapLayers;

  enabledHeatmapGribs.forEach((mapLayer) => {
    if (
      mapLayer.enabled &&
      mapLayer.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE
    ) {
      renderLayer(
        olMap,
        mapLayer,
        'heatmap',
        isSequence,
        simpleScene,
        contextValue,
        mapDef,
        skips,
        activeFramerate,
      );

      if (
        !olMap.getAllLayers().find((l) => l.get('weatherDataIsolineLayerId') === mapLayer.id) &&
        !WeatherDataLoader.getByFrameId(
          mapLayer.dataFrames[0].frameId,
          mapLayer.layerType,
          mapLayer.layerSetup.visualisationType,
        )
      ) {
        const worker = WeatherDataLoader.getInstance();
        worker.loadWeatherData(projectToPlay.id, [mapLayer], mapDef, true, () => {
          renderLayer(
            olMap,
            mapLayer,
            'heatmap',
            isSequence,
            simpleScene,
            contextValue,
            mapDef,
            skips,
            activeFramerate,
          );
        });
      }
    }
  });

  removeOlLayersHash(mapDef, layersHash[mapDef.id], olMap);
};

function animateCoordinates(
  startX: number,
  endX: number,
  startTime: number,
  endTime: number,
  currentTime: number,
) {
  // Calculate the percentage of completion based on current time
  let progress = Math.min(1, (currentTime - startTime) / (endTime - startTime));
  if (endTime === startTime) {
    /**Prevent NaN */
    progress = 1;
  }
  if (!progress) return startX;
  // Interpolate X and Y coordinates
  return lerp(startX, endX, progress);
}

// Linear interpolation function
function lerp(start: number, end: number, progress: number) {
  return start + (end - start) * progress;
}

export const isInterpolatedDrawingChanged = (
  layerGeojson: string,
  currentGeojson: string,
  nextGeojson: string,
  firstFrameTime: number,
  nextFrameTime: number,
): boolean => {
  const layerJson = JSON.parse(layerGeojson);
  const currentJson = JSON.parse(currentGeojson);
  const nextJson = JSON.parse(nextGeojson);

  // fix when last frame
  if (currentJson === nextJson) {
    return true;
  }

  const drawingType = layerJson.features[0].properties.drawingType;

  if (
    drawingType === DrawingTypeExternalEnum.LINE_STRING ||
    drawingType === DrawingTypeExternalEnum.FRONTS ||
    drawingType === DrawingTypeExternalEnum.ARROW
  ) {
    const currentTime = GlobalPlayerControl.getTime();
    const coords = currentJson.features[0].properties.arrayOfTurningPoints.flat();
    // const coords = currentJson.features[0].geometry.coordinates.flat();
    const nextCoordinates = nextJson.features[0].properties.arrayOfTurningPoints
      .flat()
      .map((c: number, i: number) => {
        return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
      });

    const curved = getSmoothLineString(chunk(nextCoordinates, 2));
    return layerJson.features[0].geometry.coordinates.flat().join('-') != curved.flat().join('-');
  }

  if (drawingType === DrawingTypeExternalEnum.POLYGON) {
    const currentTime = GlobalPlayerControl.getTime();
    const coords = currentJson.features[0].geometry.coordinates.flat().flat();
    const nextCoords = nextJson.features[0].geometry.coordinates.flat().flat();
    const nextCoordinates = nextCoords.map((c: number, i: number) => {
      return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
    });
    return (
      layerJson.features[0].geometry.coordinates.flat().join('-') !=
      nextCoordinates.flat().join('-')
    );
  }

  if (drawingType === DrawingTypeExternalEnum.IMAGE) {
    const currentTime = GlobalPlayerControl.getTime();
    // const animatedProps = {
    //   image: currentJson.features[0].properties.image,
    //   imageHeight: animateCoordinates(
    //     currentJson.features[0].properties.imageHeight,
    //     nextJson.features[0].properties.imageHeight,
    //     firstFrameTime,
    //     nextFrameTime,
    //     currentTime,
    //   ),
    //   imageWidth: animateCoordinates(
    //     currentJson.features[0].properties.imageWidth,
    //     nextJson.features[0].properties.imageWidth,
    //     firstFrameTime,
    //     nextFrameTime,
    //     currentTime,
    //   ),
    // };
    const coords = currentJson.features[0].geometry.coordinates.flat().flat();
    const nextCoords = nextJson.features[0].geometry.coordinates.flat().flat();
    const nextCoordinates = nextCoords.map((c: number, i: number) => {
      return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
    });
    return (
      layerJson.features[0].geometry.coordinates.flat().join('-') !=
      nextCoordinates.flat().join('-')
    );
  }

  return true;
};

/**
 * Enables the removal of turning points from a LineString geometry.
 * @param olMap The OpenLayers map instance.
 * @param feature The feature containing the LineString geometry.
 */
/**
 * Enables the removal of turning points from a LineString geometry.
 * @param olMap The OpenLayers map instance.
 * @param features The feature containing the LineString geometry.
 */
// export function enableTurningPointRemoval(olMap: Map, features: Feature[]) {
//   const modifyInt = new Modify({
//     features: new Collection(features),
//     deleteCondition: (event) => {
//       return event.type === 'contextmenu'; // Delete on right-click
//     },
//   });

//   olMap.addInteraction(modifyInt);

//   // Prevent the modification when not needed
//   let key: EventsKey;
//   modifyInt.on('modifystart', () => {
//     //@ts-ignore
//     const coordinates = feature?.getGeometry().getCoordinates();
//     if (coordinates.length <= 2) {
//       modifyInt.setActive(false);
//       key = modifyInt.on('modifyend', () => {
//         if (coordinates.length > 2) {
//           modifyInt.setActive(true);
//           // @ts-ignore
//           modifyInt.unByKey(key);
//         }
//       });
//     }
//   });
// }
// function isFrameOutOfSkippedTime(
//   fr: DataFrameDef & { startTime: number; endTime: number },
//   skips: SkipTimeDef[],
// ) {
//   if (!skips?.length) return true;
//   return skips.every((sk) => fr.startTime < sk.startMS || fr.endTime > sk.endMS);
// }

export function handleDrawingLayersInteractions(
  olMap: Map,
  defs: DrawingDef[],
  selectsRef: React.MutableRefObject<Record<string, Select>>,
  selectedFeatureRef: React.MutableRefObject<Feature | null>,
  snapInteractionsRef: React.MutableRefObject<Record<string, Snap>>,
  dispatch: Dispatch<AnyAction>,
  drawingLayersTranslates: React.MutableRefObject<Record<string, Translate>>,
  drawingLayersModifies: React.MutableRefObject<Record<string, Modify>>,
  activeDraw: DrawType,
  startTranslateCoordinateRef: React.MutableRefObject<Coordinate | null>,
  activeAspectRatio: [number, number],
) {
  Object.entries(selectsRef.current).forEach(([_, s]) => {
    olMap.removeInteraction(s);
    s.dispose();
  });
  Object.entries(drawingLayersTranslates.current).forEach(([_, t]) => {
    olMap.removeInteraction(t);
    t.dispose();
  });
  Object.entries(drawingLayersModifies.current).forEach(([_, m]) => {
    olMap.removeInteraction(m);
    m.dispose();
  });

  Object.entries(snapInteractionsRef.current).forEach(([_, s]) => {
    olMap.removeInteraction(s);
    s.dispose();
  });
  selectsRef.current = {};
  drawingLayersTranslates.current = {};
  drawingLayersModifies.current = {};
  defs.forEach((def) => {
    const selectInt = new Select({
      hitTolerance: 2,
      // @ts-ignore
      id: def.id,
      condition: (ev) => altKeyOnly(ev) && click(ev),
      style: getStyleFunctionDraw(olMap, activeAspectRatio, true),
      layers: (props) => {
        return props.getClassName() === `drawing-layer-${def.id}`;
      },
    });

    olMap.addInteraction(selectInt);
    selectsRef.current[def.id] = selectInt;

    if (!activeDraw.modify) {
      selectInt.on('select', (event) => {
        if (!event.selected?.[0]) return;
        selectedFeatureRef.current = event.selected?.[0];
      });
      const translateInt = new Translate({
        features: selectInt.getFeatures(),
      });
      translateInt.on('translatestart', (ev) => {
        startTranslateCoordinateRef.current = ev.coordinate;
      });
      translateInt.on('translateend', (ev) => {
        if (!ev.features?.getArray().length) return;
        const feat = ev.features.getArray()[0];
        if (!feat) return;
        if (feat.get('arrayOfTurningPoints')) {
          const turningPoints = feat.get('arrayOfTurningPoints');
          const translatedTurningPoints = getTranslatedCoordinates(
            turningPoints,
            startTranslateCoordinateRef.current,
            ev.coordinate,
          );

          feat.setProperties({ arrayOfTurningPoints: translatedTurningPoints });
        }

        if (feat.get('drawingType') === 'Circle') {
          // @ts-ignore
          feat.setProperties({ circleCenter: feat.getGeometry().flatCoordinates });
        }

        const layerEdited = getLayerByClassName(`drawing-layer-${def.id}`, olMap);

        layerEdited.setProperties({ changedByUser: true });

        // TO BE DONE - Find more reliable way of doing this
        setTimeout(() => dispatch(refreshActiveDrawEdit()), 20);
      });
      olMap.addInteraction(translateInt);
      drawingLayersTranslates.current[def.id] = translateInt;
      const snapInt = new Snap({
        features: selectInt.getFeatures(),
      });
      olMap.addInteraction(snapInt);
      snapInteractionsRef.current[def.id] = snapInt;
      // Call the enableTurningPointRemoval function here
      // !!selectedFeatureRef.current &&
      //   enableTurningPointRemoval(olMap, [selectedFeatureRef.current]);
    }
    if (activeDraw.modify) {
      const modifyInt = new Modify({
        pixelTolerance: 15,

        features: selectInt.getFeatures(),
        insertVertexCondition: (e) => {
          return e.type === 'pointerdown';
        },
        deleteCondition: (e) => shiftKeyOnly(e) && click(e),
      });

      olMap.addInteraction(modifyInt);
      drawingLayersModifies.current[def.id] = modifyInt;
      selectInt.on('select', (event) => {
        if (event.selected.length > 0) {
          // selected
          selectedFeatureRef.current = event.selected?.[0];
          selectedFeatureRef.current.setProperties({ selected: true, modifyActive: true });

          const properties = selectedFeatureRef.current!.getProperties();
          if (
            properties['drawingType'] === 'Front' ||
            properties['drawingType'] === undefined ||
            properties['arrayOfTurningPoints']
          ) {
            let coordinates = properties['arrayOfTurningPoints'];
            coordinates = cleanDuplcateCoordinatesForBezierCurve(coordinates);
            if (coordinates) {
              // @ts-ignore
              selectedFeatureRef.current!.getGeometry()!.setCoordinates(coordinates);
            }
          }
        } else {
          // deselected change happens save changes
          const properties = selectedFeatureRef.current!.getProperties();
          if (
            properties['drawingType'] === 'Front' ||
            properties['drawingType'] === undefined ||
            properties['arrayOfTurningPoints']
          ) {
            const arrayOfTurningPoints = cloneDeep(
              // @ts-ignore
              selectedFeatureRef!.current!.getGeometry()!.getCoordinates(),
            );
            // @ts-ignore
            selectedFeatureRef.current!.setProperties({
              // @ts-ignore
              arrayOfTurningPoints: arrayOfTurningPoints,
              selected: false,
              modifyActive: false,
            });
            const curved = getSmoothLineString(arrayOfTurningPoints);

            // @ts-ignore
            selectedFeatureRef.current.getGeometry().setCoordinates(curved);
          }
          /**Set changedByUser property */
          const layerEdited = getLayerByClassName(`drawing-layer-${def.id}`, olMap);

          layerEdited.setProperties({ changedByUser: true });
          dispatch(setActiveDraw({ newValue: false, path: 'modify' }));
          // TO BE DONE - Find more reliable way of doing this
          setTimeout(() => dispatch(refreshActiveDrawEdit()), 20);
        }
      });
      const snapInt = new Snap({
        features: selectInt.getFeatures(),
      });
      olMap.addInteraction(snapInt);
      snapInteractionsRef.current[def.id] = snapInt;
      // !!selectedFeatureRef.current &&
      //   enableTurningPointRemoval(olMap, [selectedFeatureRef.current]);
    }
  });
}

export function handleHighlightFeature(
  map: OlMap,
  featureId: string | null,
  drawingId: string | null,
  activeAspectRatio: [number, number],
) {
  if (!featureId || !drawingId || !map) return;
  const layer = getLayerByClassName(`drawing-layer-${drawingId}`, map) as VectorLayer<
    VectorSource<Geometry>
  >;
  if (!layer) return;
  const feature = layer
    .getSource()!
    .getFeatures()
    ?.find((f) => f.getProperties().featureId === featureId) as Feature<Geometry>;
  if (!feature) return;
  const properties = feature.getProperties();
  if (!properties?.selected) {
    feature.setStyle(getStyleFunctionDraw(map, activeAspectRatio, true));
    // TO BE DONE reconsider this
    setTimeout(
      () => feature.setStyle(getStyleFunctionDraw(map, activeAspectRatio, false, true)),
      500,
    );
  }
}

export function getOcean(olMap: OlMap) {
  return olMap?.getAllLayers()?.find((l) => l.get('id') == 'ocean-c9');
}

export function removeOlLayersHash(
  mapDef: MapPanelDef,
  existingLayers: Record<string, { layer: Layer | null; loading: boolean }>,
  map: OlMap,
) {
  if (!existingLayers) return;

  const incomingLayers: Record<string, boolean> = {};
  const enabledHeatmapGribs = mapDef.wdSpace[0].gribMapLayers;
  enabledHeatmapGribs.forEach((hg) => {
    if (!hg.enabled || hg.layerSetup.visualisationType != VisualisationTypeEnum.ISOLINE) return;
    hg.dataFrames.forEach((fr) => {
      incomingLayers[`${hg.id}-${fr?.timestamp}-c9layer-heatmap`] = true;
    });
  });

  const existingLayersKeys = Object.keys(existingLayers);

  existingLayersKeys.forEach((k) => {
    if (!incomingLayers[k] && k.includes('c9') && existingLayers[k].layer != null) {
      map.removeLayer(existingLayers[k].layer!);
      existingLayers[k].layer?.dispose();
      layersHash[mapDef.id][k]?.layer?.dispose();
      delete layersHash[mapDef.id][k];
    }
  });
}

export function haversineDistance(coords1: [number, number], coords2: [number, number]) {
  function toRad(x: number) {
    return (x * Math.PI) / 180;
  }

  const lon1 = coords1[0];
  const lat1 = coords1[1];

  const lon2 = coords2[0];
  const lat2 = coords2[1];

  const R = 6371; // km

  const x1 = lat2 - lat1;
  const dLat = toRad(x1);
  const x2 = lon2 - lon1;
  const dLon = toRad(x2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c * 1000;
}

export function getLonLatFromOldAndOffset(
  lonLat: [number, number] | Coordinate,
  offsetX: number,
  offsetY: number,
  resolution: number,
) {
  const [lon, lat] = lonLat;
  const distanceX = resolution * offsetX;
  const distanceY = resolution * offsetY;
  const R = 6371000; // m
  const newLat = lat + (distanceY / R) * (180 / Math.PI);
  const newLon = lon + ((distanceX / R) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180);
  return [newLon, newLat];
}

export function getOverlayCoordsForOffset(
  lonLat: [number, number] | Coordinate,
  offsetX: number,
  offsetY: number,
  resolution: number,
  projection: ProjectionLike,
) {
  const newCoordsLonLat = getLonLatFromOldAndOffset(lonLat, offsetX, offsetY, resolution);
  return fromLonLat(newCoordsLonLat, projection) as [number, number];
}

export function getOverlayDefaultPosition(poster: WeatherPosterDef) {
  let lonLat: [number, number];
  const isObserved = Boolean(poster.observedWDElements?.length);
  if (isObserved) {
    lonLat = [
      poster.observedWDElements[0].observedWDSource.station.longitude,
      poster.observedWDElements[0].observedWDSource.station.latitude,
    ];
  } else {
    lonLat = [
      poster.forecastWDElements[0].forecastWDSource.location.longitude,
      poster.forecastWDElements[0].forecastWDSource.location.latitude,
    ];
  }
  return lonLat;
}

export function mapWeatherPosterToGeo(poster: WeatherPosterDef): WeatherGeoPosterDef {
  const [lon, lat] = getOverlayDefaultPosition(poster);
  return { ...poster, anchorOffsetX: 0, anchorOffsetY: 0, longitude: lon, latitude: lat };
}

export function getResolutionForMapConstraint(
  distance: number,
  direction: 'W' | 'E' | 'N' | 'S',
  activeAspectRatio: [number, number],
  previewSize: number,
) {
  const pixelsToTopBtm = previewSize / 2; // starting from center
  const width = (activeAspectRatio[0] / activeAspectRatio[1]) * previewSize;
  const pixelsToLeftRight = width / 2;
  switch (direction) {
    case 'N':
    case 'S': {
      return distance / pixelsToTopBtm;
    }
    case 'W':
    case 'E': {
      return distance / pixelsToLeftRight;
    }
  }
}

export function getZindexOfMapLayer(mapPanel: MapPanelDef) {
  const gribs = mapPanel.wdSpace[0].gribMapLayers;
  const radars = mapPanel.wdSpace[0].radarMapLayers;
  const sat = mapPanel.wdSpace[0].satelliteMapLayers;
  const vectors = mapPanel.wdSpace[0].vectorMapLayers;
  const citiesGeoPosters = mapPanel.cityPosters;
  const indexes: number[] = [];
  gribs.forEach((g) => indexes.push(g.zindex));
  radars.forEach((r) => indexes.push(r.zindex));
  sat.forEach((s) => {
    if (s.zindex !== undefined && !isNull(s.zindex)) indexes.push(s.zindex);
  });
  vectors.forEach((v) => indexes.push(v.zindex));
  citiesGeoPosters.forEach((gp) => {
    const ind = gp.positionControl?.zindex;
    if (ind !== undefined && !isNull(ind)) indexes.push(ind);
  });
  if (!indexes.length) return 1;
  return Math.max(...indexes) + 1;
}

export function getMaxHeatmapZindex(mapPanel: MapPanelDef) {
  const heatmapsIndexes = mapPanel.wdSpace[0].gribMapLayers
    .filter((g) => g.gribSource.parameterType.name === '2 metre temperature')
    .map((g) => g.zindex);
  if (!heatmapsIndexes.length) return 1;
  return Math.max(...heatmapsIndexes);
}

export function createOceanLayer(oceanZindex: number) {
  return new VectorLayer({
    // @ts-ignore
    id: 'ocean-c9',
    zIndex: oceanZindex + 0.1,
    source: new VectorSource({
      url: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_ocean.geojson',
      format: new GeoJSON(),
      // @ts-ignore
      crossOrigin: 'anonymous',
    }),
    style: new Style({
      fill: new Fill({
        color: '#09C3DB',
      }),
    }),
  });
}

export function getAllLayersForLegend(mapDef: MapPanelDef) {
  const gribPalettes = mapDef.wdSpace[0].gribMapLayers
    .filter((l) => l.enabled)
    .map((g) => g.layerSetup);
  const radarPalettes = mapDef.wdSpace[0].radarMapLayers
    .filter((l) => l.enabled)
    .map((g) => g.layerSetup);
  const satellitePalettes = mapDef.wdSpace[0].satelliteMapLayers
    .filter((l) => l.enabled)
    .map((g) => g.layerSetup);
  return [...gribPalettes, ...radarPalettes, ...satellitePalettes].filter(
    (p) =>
      Boolean(p.colorPaletteDef?.colorStops?.pallet) &&
      Boolean(Object.keys(p.colorPaletteDef!.colorStops.pallet)) &&
      p?.displayPaletteLegend,
  );
}

export function hasAnyLayerOnMapInit(mapDef: MapPanelDef) {
  const hasGribs = Boolean(
    mapDef.wdSpace[0].gribMapLayers.filter(
      (g) => g.enabled && g.timeControls.length && g.timeControls[0].startMS === 0,
    ).length,
  );
  const hasRadars = Boolean(
    mapDef.wdSpace[0].radarMapLayers.filter(
      (r) => r.enabled && r.timeControls.length && r.timeControls[0].startMS === 0,
    ).length,
  );
  const hasSatellites = Boolean(
    mapDef.wdSpace[0].radarMapLayers.filter(
      (s) => s.enabled && s.timeControls.length && s.timeControls[0].startMS === 0,
    ).length,
  );
  return hasGribs || hasRadars || hasSatellites;
}

export function hasAnyLayer(mapDef: MapPanelDef) {
  const hasGribs = Boolean(mapDef.wdSpace[0].gribMapLayers.filter((g) => g.enabled).length);
  const hasRadars = Boolean(mapDef.wdSpace[0].radarMapLayers.filter((r) => r.enabled).length);
  const hasSatellites = Boolean(mapDef.wdSpace[0].radarMapLayers.filter((s) => s.enabled).length);
  return hasGribs || hasRadars || hasSatellites;
}

export function extentMercBoundingBox(box: [number, number, number, number]) {
  box[0] - 1 >= -180 ? (box[0] = box[0] - 1) : (box[0] = -180);
  box[1] - 1 >= -85 ? (box[1] = box[1] - 1) : (box[1] = -85);
  box[2] + 1 <= 180 ? (box[2] = box[2] + 1) : (box[2] = 180);
  box[3] + 1 <= 85 ? (box[3] = box[3] + 1) : (box[3] = 85);
  return box;
}

export function createBaseMapTilesURL(mapType: string, projectionString?: string, version = 'v2') {
  let url = `${process.env.REACT_APP_BASE_MAP_URL}/tiles/${version}/${
    mapType === 'SATELLITE' ? 'po-satellite' : mapType
  }/{z}/{x}/{y}.png`;
  if (projectionString) {
    url += `?srs=${encodeURIComponent(projectionString)}`;
  }
  return url;
}
// HD_1920x1080_60p
export function getExportResolution(exportFormat: string): [number, number] {
  const splitted = exportFormat.split('_')[1];
  return splitted.split('x').map((s) => Number(s)) as [number, number];
}

export function addCircleToGeoJson(parsedJson: any) {
  // mutates geojson object
  parsedJson.features.forEach((f: any) => {
    if (f.properties?.drawingType === 'Circle') {
      f.geometry = {
        type: 'Point',
        coordinates: f.properties.circleCenter,
      };
      delete f.geometries;
    }
  });
}

let COASTLINE_GEOJSON: any = null;

function addCoastline(
  geojsonObject: any,
  mapPanelDef: MapPanelDef,
  mapDef: OlMap,
  layer: VectorMapLayer,
  parsedStyle: any,
  className: string,
  shouldRemove: boolean,
) {
  const isGVNSP = mapDef.getView().getProjection().getCode().includes('ESRI:54049');

  if (isGVNSP) {
    const { projectionCenterLat, projectionCenterLon } = mapPanelDef.properties;
    for (let i = 0; i < geojsonObject.features.length; i++) {
      let k = 0;
      let newLine = false;
      while (k < geojsonObject.features[i].geometry.coordinates.length) {
        const [lon, lat] = geojsonObject.features[i].geometry.coordinates[k];
        const dist = distance(lat, lon, Number(projectionCenterLat), Number(projectionCenterLon));
        if (dist > 6371) {
          // 6371 km is earth radius, if the coordinate is more than R away, remove it
          geojsonObject.features[i].geometry.coordinates.splice(k, 1);
          // we make a new line when we remove coordinates to prevent coordinates being removed in the middle which will cause a straight line
          newLine = true;
        } else {
          // if we're making a new line, take all the rest of the coordinates from this one and add then to the new one
          if (newLine) {
            const newLineCoordinates = geojsonObject.features[i].geometry.coordinates.splice(k);
            const newFeature = structuredClone(geojsonObject.features[i]);
            newFeature.geometry.coordinates = newLineCoordinates;
            geojsonObject.features.splice(i + 1, 0, newFeature);
            newLine = false;
          }
          k++;
        }
      }
    }
  }

  const source = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject, {
      featureProjection: mapDef.getView().getProjection(),
      dataProjection: 'EPSG:4326',
    }),
  });

  const vLayer = new VectorImageLayer({
    className,
    source,
    zIndex: layer.zindex,
    style: () => {
      return new Style({
        stroke: new Stroke({
          color: parsedStyle.strokeColor,
          width: parsedStyle.strokeWidth,
        }),
      });
    },
  });
  vLayer.setProperties({
    zindex: parsedStyle.zindex,
    strokeColor: parsedStyle.strokeColor,
    strokeWidth: parsedStyle.strokeWidth,
  });
  if (shouldRemove) mapDef.addLayer(vLayer);
}

let MAPS_WITH_COASTLINE_LOADING: string[] = [];

export function addBaseVectorLayer(
  layer: VectorMapLayer,
  mapDef: OlMap,
  projection: ProjectionLike,
  projString: string,
  mapPanelDef: MapPanelDef,
) {
  const className = `base-map-layer-${layer.type}`;
  const existingLayers = mapDef.getLayers().getArray();
  let parsedStyle: typeof defaultStyle;
  try {
    parsedStyle = JSON.parse(layer.style);
  } catch (e) {
    console.error('Parse style error ', e);
    parsedStyle = defaultStyle;
  }
  let shouldRemove = true;
  const layerFound = existingLayers.find((lyr) => lyr.getClassName() === className);
  if (layerFound) {
    // @ts-ignore
    const { strokeColor, zindex, strokeWidth } = layerFound?.getProperties();
    layerFound?.setZIndex(layer.zindex);
    shouldRemove =
      strokeColor !== parsedStyle.strokeColor ||
      strokeWidth !== parsedStyle.strokeWidth ||
      zindex !== parsedStyle.zindex;
    if (shouldRemove) {
      mapDef.removeLayer(layerFound!);
      layerFound?.dispose();
    }
  }
  if (!shouldRemove) return;
  if (
    layer.type == MapLayersEnum.COASTLINE &&
    // Using mvtiles for UTM projection because of issue NIMA-1675
    !mapPanelDef.baseMapSetup.projectionParams?.includes('+proj=utm')
  ) {
    if (!MAPS_WITH_COASTLINE_LOADING.includes(mapPanelDef.id)) {
      MAPS_WITH_COASTLINE_LOADING.push(mapPanelDef.id);
      if (COASTLINE_GEOJSON) {
        addCoastline(
          COASTLINE_GEOJSON,
          mapPanelDef,
          mapDef,
          layer,
          parsedStyle,
          className,
          shouldRemove,
        );
        MAPS_WITH_COASTLINE_LOADING = MAPS_WITH_COASTLINE_LOADING.filter(
          (x) => x !== mapPanelDef.id,
        );
      } else {
        fetch(`/ne_10m_coastline.geojson`).then(async (response) => {
          const geojsonObject = await response.json();
          COASTLINE_GEOJSON = geojsonObject;
          addCoastline(
            COASTLINE_GEOJSON,
            mapPanelDef,
            mapDef,
            layer,
            parsedStyle,
            className,
            shouldRemove,
          );
          MAPS_WITH_COASTLINE_LOADING = MAPS_WITH_COASTLINE_LOADING.filter(
            (x) => x !== mapPanelDef.id,
          );
        });
      }
    }
  } else {
    const url = `${process.env.REACT_APP_BASE_MAP_URL}/mvtiles/v2/{z}/{x}/{y}.mvt?features=${
      layer.type
    },test&srs=${encodeURIComponent(projString)}`;
    const vLayer = new VectorTileLayer({
      className,
      source: new VectorTileSource({
        url,
        projection,
        format: new MVT(),
      }),
      zIndex: layer.zindex,
      style: () => {
        return new Style({
          stroke: new Stroke({
            color: parsedStyle.strokeColor,
            width: parsedStyle.strokeWidth,
          }),
        });
      },
      // declutter: true,
    });
    vLayer.setProperties({
      zindex: parsedStyle.zindex,
      strokeColor: parsedStyle.strokeColor,
      strokeWidth: parsedStyle.strokeWidth,
      loadedDensity: 1,
    });
    if (shouldRemove) mapDef.addLayer(vLayer);
  }
}

export function cleanUpCustomVectorLayers(
  layersToShow: CustomVectorLayer[] | VectorMapLayer[],
  mapDef: OlMap,
) {
  const allCustomOnMapLayers = mapDef
    .getAllLayers()
    .filter((l) => l.getClassName()?.includes('custom-map-layer-'));
  const classes = layersToShow.map((l) => `custom-map-layer-${l.id}`);
  allCustomOnMapLayers.forEach((l) => {
    if (!classes.includes(l.getClassName())) {
      mapDef.removeLayer(l);
      l.dispose();
    }
  });
}

export function addCustomVectorLayer(
  layer: CustomVectorLayer | VectorMapLayer,
  mapDef: OlMap,
  projection: ProjectionLike,
  projString: string,
) {
  const existingLayers = mapDef.getAllLayers();
  const className = `custom-map-layer-${layer.id}`;
  const layerFound = existingLayers.find(
    (lyr) => lyr.getClassName() === className,
  ) as VectorTileLayer;

  let shouldRemove = true;
  if (layerFound) {
    const stylesSetInProperties = layerFound.getProperties();
    const incomingLayerStyle = JSON.parse(layer.style);

    const isSame =
      stylesSetInProperties['color'] === incomingLayerStyle['strokeColor'] &&
      stylesSetInProperties['width'] === incomingLayerStyle['strokeWidth'] &&
      stylesSetInProperties['zindex'] === incomingLayerStyle['zindex'];
    if (isSame) {
      shouldRemove = false;
    }
  }

  if (!shouldRemove) return;
  if (layerFound) {
    mapDef.removeLayer(layerFound);
    layerFound.dispose();
  }

  const mvtSource = new VectorTileSource({
    format: new MVT(),
    url: layer.baseMapUrl + `&srs=${encodeURIComponent(projString)}`,
    projection: projection,
  });

  const layerStyle = JSON.parse(layer.style);

  const zIndex = isNaN(Number(layerStyle.zindex)) ? 1 : Number(layerStyle.zindex);
  const mvtLayer = new VectorTileLayer({
    source: mvtSource,
    className: className,
    zIndex,
    style: () => {
      return new Style({
        stroke: new Stroke({
          color: layerStyle.strokeColor,
          width: layerStyle.strokeWidth,
        }),
      });
    },
  });

  mvtLayer.setProperties({
    color: layerStyle.strokeColor,
    width: layerStyle.strokeWidth,
    zindex: zIndex,
  });

  mapDef.addLayer(mvtLayer);
}

export function getAllLayerSetupsInScene(scene: SceneDef, mode: ModeEnum) {
  if (!scene) return [];
  if (scene?.mapPanels?.length < 0) return [];
  return scene?.mapPanels
    ?.map((md) => [
      ...md.wdSpace[0].gribMapLayers.map((g) => ({
        ...g.layerSetup,
        timeControls: mode === ModeEnum.PROJECT ? g.layerSetup.paletteTC : g.timeControls,
        positionControl: g.layerSetup.paletteLegendPositionControl,
        enabled: g.enabled,
        mapId: md.id,
        mapLevel: md.positionControl?.zindex || 0,
      })),
      ...md.wdSpace[0].radarMapLayers.map((r) => ({
        ...r.layerSetup,
        timeControls: mode === ModeEnum.PROJECT ? r.layerSetup.paletteTC : r.timeControls,
        positionControl: r.layerSetup.paletteLegendPositionControl,
        enabled: r.enabled,
        mapId: md.id,
        mapLevel: md.positionControl?.zindex || 0,
      })),
      ...md.wdSpace[0].satelliteMapLayers.map((s) => {
        return {
          ...s.layerSetup,
          timeControls: mode === ModeEnum.PROJECT ? s.layerSetup.paletteTC : s.timeControls,
          positionControl: s.layerSetup.paletteLegendPositionControl,
          enabled: s.enabled,
          mapId: md.id,
          mapLevel: md.positionControl?.zindex || 0,
        };
      }),
    ])
    .flat(1)
    .filter((l) => l.displayPaletteLegend && l.enabled);
}

// For reducer

export function getAllLayerSetupsInSceneReducer(scene: SceneDef) {
  if (!scene) return [];
  const layers: WeatherDataMapLayerSetup[] = [];
  scene.mapPanels.forEach((m) => {
    m.wdSpace[0].gribMapLayers.forEach((l) => {
      layers.push(l.layerSetup);
    });
    m.wdSpace[0].radarMapLayers.forEach((l) => {
      layers.push(l.layerSetup);
    });
    m.wdSpace[0].satelliteMapLayers.forEach((l) => {
      layers.push(l.layerSetup);
    });
  });
  return layers;
}

function getAbsoluteFromPercent(percent: number, total: number) {
  return (percent / 100) * total;
}

export function transformRGBA(colorString: string) {
  // @ts-ignore
  const [red, green, blue, alpha] = colorString.match(/\d+/g);
  const scaledAlpha = parseFloat(alpha) / 255;
  return `rgba(${red}, ${green}, ${blue}, ${scaledAlpha})`;
}

export function detransformRGBA(colorString: string) {
  // @ts-ignore
  const [red, green, blue, alpha] = colorString.match(/\d+/g);
  const scaledAlpha = Math.round(alpha * 255);
  return `rgba(${red}, ${green}, ${blue}, ${scaledAlpha})`;
}

export const cityStyle = (previewSize: number, activeAspectRatio: [number, number]): StyleLike => {
  return function (feature) {
    const isImage = feature.getProperties().type == 'image';
    const anchorSize = feature.getProperties().enable
      ? getAbsoluteFromPercent(Number(feature.getProperties().size || 0), previewSize)
      : 0;
    const fontSize = getAbsoluteFromPercent(
      Number(feature.getProperties().fontSize || 1),
      previewSize,
    );
    const imageURL = feature.getProperties().imageURL;
    const anchorWidth = transformPercentToAbsolute(
      Number(feature.getProperties().width || 0),
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
    );
    const anchorHeight = transformPercentToAbsolute(
      Number(feature.getProperties().height || 0),
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );

    const isCircle = feature.getProperties().shape === 'circle';
    const isSquare = feature.getProperties().shape === 'square';
    const fontAlignment: 'left' | 'right' | 'center' = feature.getProperties().fontAlignment;
    const borderWidth = !isNaN(Number(feature.getProperties().borderWidth))
      ? Number(feature.getProperties().borderWidth)
      : 0;
    let offsetX = undefined;
    let offsetY = undefined;
    if (fontAlignment === 'left')
      offsetX = isImage && imageURL ? anchorWidth / 2 + 30 : anchorSize + (isCircle ? 6 : 3);
    if (fontAlignment === 'right')
      offsetX = isImage && imageURL ? -anchorWidth / 2 - 30 : -anchorSize - (isCircle ? 6 : 3);
    if (fontAlignment === 'center')
      offsetY = isImage && imageURL ? anchorHeight / 2 + fontSize : anchorSize + fontSize / 2;

    let rgbaFill = feature.getProperties().fillColor as string;
    let rgbaFont = feature.getProperties().fontColor as string;
    let rgbaBorder = feature.getProperties().borderColor as string;
    let rgbaStroke = feature.getProperties().strokeColor as string;
    const strokeWidth = getAbsoluteFromPercent(
      Number(feature.getProperties().strokeWidth || 0),
      previewSize,
    );

    if (rgbaFill.startsWith('rgba')) {
      rgbaFill = transformRGBA(rgbaFill);
    }
    if (rgbaFont.startsWith('rgba')) {
      rgbaFont = transformRGBA(rgbaFont);
    }
    if (rgbaBorder.startsWith('rgba')) {
      rgbaBorder = transformRGBA(rgbaBorder);
    }
    if (rgbaStroke && rgbaStroke.startsWith('rgba')) {
      rgbaStroke = transformRGBA(rgbaStroke);
    }

    const fill = new Fill({
      color: rgbaFill,
    });
    const stroke = new Stroke({
      color: borderWidth === 0 ? 'transparent' : rgbaBorder,
      width: borderWidth,
    });

    const circleStyle = new Circle({
      radius: anchorSize,
      fill,
      stroke,
    });
    const squareStyle = new RegularShape({
      fill,
      stroke,
      points: 4,
      radius: anchorSize,
      angle: Math.PI / 4,
    });
    const crossStyle = new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: anchorSize,
      radius2: 0,
      angle: Math.PI / 4,
    });
    const textStyle = new Text({
      font: `${fontSize}px ${feature.getProperties().fontFamily} ${
        feature.getProperties().fontType
      }`,
      placement: 'line',
      textAlign: feature.getProperties().fontAlignment,
      overflow: true,
      textBaseline: 'middle',
      offsetX,
      offsetY,
      fill: new Fill({
        color: rgbaFont,
      }),
      stroke:
        strokeWidth > 0
          ? new Stroke({
              color: rgbaStroke ?? 'rgba(255, 255, 255, 255)',
              width: strokeWidth,
            })
          : undefined,
    });

    let imageStyle: ImageStyle = circleStyle;
    if (isSquare) {
      imageStyle = squareStyle;
    }
    if (!isCircle && !isSquare) {
      imageStyle = crossStyle;
    }
    if (isImage && imageURL) {
      imageStyle = new Icon({
        src: imageURL,
        width: anchorWidth,
        height: anchorHeight,
      });
    }
    const anchorStyle = new Style({
      image: imageStyle,
      text: textStyle,
    });
    const label = feature.getProperties().label;
    const textTransform = feature.getProperties().textTransform;
    if (textTransform === 'uppercase') {
      anchorStyle.getText().setText(label.toUpperCase());
    } else if (textTransform === 'lowercase') {
      anchorStyle.getText().setText(label.toLowerCase());
    } else {
      anchorStyle.getText().setText(label);
    }
    return anchorStyle;
  };
};

export const cityStyleSelect = (
  previewSize: number,
  activeAspectRatio: [number, number],
): StyleLike => {
  return function (feature) {
    const isImage = feature.getProperties().type == 'image';
    const anchorSize = feature.getProperties().enable
      ? getAbsoluteFromPercent(Number(feature.getProperties().size || 0), previewSize)
      : 0;
    const fontSize = getAbsoluteFromPercent(
      Number(feature.getProperties().fontSize || 1),
      previewSize,
    );
    const isCircle = feature.getProperties().shape === 'circle';
    const isSquare = feature.getProperties().shape === 'square';
    const imageURL = feature.getProperties().imageURL;
    const anchorWidth = transformPercentToAbsolute(
      Number(feature.getProperties().width || 0),
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
    );
    const anchorHeight = transformPercentToAbsolute(
      Number(feature.getProperties().height || 0),
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    const fontAlignment: 'left' | 'right' | 'center' = feature.getProperties().fontAlignment;
    let offsetX = undefined;
    let offsetY = undefined;
    if (fontAlignment === 'left')
      offsetX = isImage && imageURL ? anchorWidth / 2 + 30 : anchorSize + (isCircle ? 6 : 3);
    if (fontAlignment === 'right')
      offsetX = isImage && imageURL ? -anchorWidth / 2 - 30 : -anchorSize - (isCircle ? 6 : 3);
    if (fontAlignment === 'center')
      offsetY = isImage && imageURL ? anchorHeight / 2 + fontSize : anchorSize + fontSize / 2;

    const fill = new Fill({
      color: 'red',
    });
    const stroke = new Stroke({
      color: 'red',
      width: 1,
    });
    const circleStyle = new Circle({
      radius: anchorSize,
      fill,
      stroke,
    });
    const squareStyle = new RegularShape({
      fill,
      stroke,
      points: 4,
      radius: anchorSize,
      angle: Math.PI / 4,
    });
    const crossStyle = new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: anchorSize,
      radius2: 0,
      angle: Math.PI / 4,
    });
    let imageStyle;
    if (isImage && imageURL) {
      imageStyle = new Icon({
        src: imageURL,
        width: anchorWidth,
        height: anchorHeight,
      });
    }
    const textStyle = new Text({
      font: `${fontSize}px ${feature.getProperties().fontFamily} ${
        feature.getProperties().fontType
      }`,
      placement: 'line',
      textAlign: feature.getProperties().fontAlignment,
      overflow: true,
      textBaseline: 'middle',
      offsetX,
      offsetY,
      fill: new Fill({
        color: 'red',
      }),
    });
    const anchorStyle = new Style({
      image: isImage ? imageStyle : isCircle ? circleStyle : isSquare ? squareStyle : crossStyle,
      text: textStyle,
    });

    const label = feature.getProperties().label;
    const textTransform = feature.getProperties().textTransform;
    if (textTransform === 'uppercase') {
      anchorStyle.getText().setText(label.toUpperCase());
    } else if (textTransform === 'lowercase') {
      anchorStyle.getText().setText(label.toLowerCase());
    } else {
      anchorStyle.getText().setText(label);
    }
    return anchorStyle;
  };
};

export const delayMS = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function getLatitudeFromCenterToTopMap(map: Map, latitude: number, toBottom = false) {
  const res = map.getView().getResolution()!;
  const [, height] = map!.getSize()!;
  const meters = res * (height / 2);
  return toBottom ? latitude - meters / 111000 : latitude + meters / 111000;
}

export function correctLongitude(lon: number) {
  while (lon < -180) {
    lon += 360;
  }
  while (lon > 180) {
    lon -= 360;
  }
  return lon;
}

export const getStyleFunctionDraw = (
  mapCurr: Map,
  activeAspectRatio: [number, number],
  isSelected = false,
  imageHack = false,
): StyleLike => {
  return (feature, resolution) => {
    const properties = feature.getProperties();

    const drawingType = properties['drawingType'];
    if (drawingType === 'Front') {
      return styleForFronts(feature, resolution, isSelected, '', false);
    } else if (drawingType === 'Arrow') {
      return styleFunctionForArrow(feature, resolution, mapCurr, isSelected);
    } else if (drawingType === 'Circle') {
      return getStyleForCircle(properties, isSelected);
    } else if (drawingType === 'Image') {
      return getStyleForImage(properties, activeAspectRatio, isSelected, imageHack);
    } else {
      return styleFunctionForBasicShapes(
        properties['lineColor'],
        properties['fill'],
        properties['fillColor'],
        properties['lineThickness'],
        isSelected,
      );
    }
  };
};

export function getLayerByClassName(className: string, map: Map) {
  return map.getAllLayers().find((l) => l.getClassName() == className)!;
}

export function getDrawingType(drawingType: DrawingTypeEnum): string {
  switch (drawingType) {
    case DrawingTypeEnum.CLOSED_CURVED_LINE:
      return 'closedCurvedLine';
    case DrawingTypeEnum.FRONTS:
      return 'Front';
    case DrawingTypeEnum.LINE_STRING:
      return 'LineString';
    case DrawingTypeEnum.ARROW:
    case DrawingTypeEnum.CIRCLE:
    case DrawingTypeEnum.POLYGON:
    case DrawingTypeEnum.RECTANGLE:
    case DrawingTypeEnum.IMAGE:
      return drawingType;
  }
}

export function getDrawingTypeReverse(
  drawingType:
    | 'closedCurvedLine'
    | 'Front'
    | 'LineString'
    | DrawingTypeEnum.ARROW
    | DrawingTypeEnum.CIRCLE
    | DrawingTypeEnum.POLYGON
    | DrawingTypeEnum.IMAGE
    | DrawingTypeEnum.RECTANGLE,
): DrawingTypeEnum {
  switch (drawingType) {
    case 'closedCurvedLine':
      return DrawingTypeEnum.CLOSED_CURVED_LINE;
    case 'Front':
      return DrawingTypeEnum.FRONTS;
    case 'LineString':
      return DrawingTypeEnum.LINE_STRING;
    case DrawingTypeEnum.ARROW:
    case DrawingTypeEnum.CIRCLE:
    case DrawingTypeEnum.POLYGON:
    case DrawingTypeEnum.IMAGE:
    case DrawingTypeEnum.RECTANGLE:
      return drawingType;
    default: {
      throw new Error('Feature drawing type missmatch');
    }
  }
}

export function getFrontType(frontType: FrontTypeEnum): string {
  switch (frontType) {
    case FrontTypeEnum.COLD_FRONT:
      return 'coldFront';
    case FrontTypeEnum.WARM_FRONT:
      return 'warmFront';
    case FrontTypeEnum.STATIONARY_FRONT:
      return 'stationaryFront';
    case FrontTypeEnum.OCCLUDED_FRONT:
      return 'occludedFront';
    case FrontTypeEnum.OCCLUDED_FRONT_V2:
      return 'occludedFrontV2';
    case FrontTypeEnum.THROUGH_OR_OUTFLOW_BOUNDARY:
      return 'troughOrOutflowBoundary';
    default:
      return 'warmFront';
  }
}

export function getFrontTypeReverse(
  frontType:
    | 'coldFront'
    | 'warmFront'
    | 'stationaryFront'
    | 'occludedFront'
    | 'occludedFrontV2'
    | 'troughOrOutflowBoundary',
): FrontTypeEnum {
  switch (frontType) {
    case 'coldFront':
      return FrontTypeEnum.COLD_FRONT;
    case 'warmFront':
      return FrontTypeEnum.WARM_FRONT;
    case 'stationaryFront':
      return FrontTypeEnum.STATIONARY_FRONT;
    case 'occludedFront':
      return FrontTypeEnum.OCCLUDED_FRONT;
    case 'occludedFrontV2':
      return FrontTypeEnum.OCCLUDED_FRONT_V2;
    case 'troughOrOutflowBoundary':
      return FrontTypeEnum.THROUGH_OR_OUTFLOW_BOUNDARY;
    default:
      return FrontTypeEnum.WARM_FRONT;
  }
}

export const filterWithSelection = (
  sortedFrames: DataFrameDef[],
  density: number,
  startFrame: DataFrameDef,
) => {
  const diff = getMedianDifferenceFromTimestamp(sortedFrames);
  const normalisedFrames = getNormalisedFrames(sortedFrames);
  const findFrameIndex = startFrame
    ? normalisedFrames.findIndex(
        (frame) => frame.frameId === startFrame.frameId && frame.timestamp === startFrame.timestamp,
      )
    : 0;
  const startingFrameIndex = findFrameIndex !== -1 ? findFrameIndex : 0;
  const minDensity = diff / SECONDS_PER_HOUR > density ? diff / SECONDS_PER_HOUR : density;
  // Split the array based on the starting frame and return the first part, including that element
  const framesFirstPart = normalisedFrames.slice(0, startingFrameIndex + 1);
  const framesSecondPart = normalisedFrames.slice(startingFrameIndex);
  const firstPart = filterFramesByTimeWindow(framesFirstPart, minDensity, startFrame);
  const secondPart = filterFramesByTimeWindowUp(framesSecondPart, minDensity, startFrame);
  return [...firstPart, ...secondPart];
};

export function filterFramesByTimeWindow(
  sortedFrames: DataFrameDef[],
  density: number,
  startFrame?: DataFrameDef,
) {
  const timeWindow = density * SECONDS_PER_HOUR;
  if (!sortedFrames?.length) return [];
  const normalisedFrames = getNormalisedFrames(sortedFrames);
  const lastFrame = normalisedFrames.pop()!;
  let start = lastFrame.timestamp - timeWindow;
  const filteredFrames = [lastFrame];
  if (density === 0) return normalisedFrames;
  for (let i = normalisedFrames.length - 1; i >= 0; i--) {
    const frame = normalisedFrames[i];
    if (frame.timestamp <= start) {
      if (frame.timestamp >= start - timeWindow) {
        filteredFrames.unshift(frame);
      } else {
        while (start >= frame.timestamp) {
          start = start - timeWindow;
        }
        filteredFrames.unshift(frame);
      }
      start = start - timeWindow;
    }
  }
  return filteredFrames;
}
export function filterFramesByTimeWindowUp(
  sortedFrames: DataFrameDef[],
  density: number,
  startFrame: DataFrameDef,
) {
  const timeWindow = density * SECONDS_PER_HOUR;
  if (!sortedFrames?.length) return [];
  const normalisedFrames = getNormalisedFrames(sortedFrames);

  const filteredFrames: DataFrameDef[] = [];

  if (density === 0) return filteredFrames;

  let start = normalisedFrames[0].timestamp - timeWindow;

  for (let i = 0; i < normalisedFrames.length; i++) {
    const frame = normalisedFrames[i];
    if (frame.timestamp >= start) {
      if (frame.timestamp <= start + timeWindow) {
        filteredFrames.push(frame);
      } else {
        while (start <= frame.timestamp) {
          start = start + timeWindow;
        }
        filteredFrames.push(frame);
      }
      start = start + timeWindow;
    }
  }

  return filteredFrames.slice(2);
}

export function findIndexesAfterDensityFramesChange(
  selected: DataFrameDef[],
  usable: DataFrameDef[],
) {
  if (!selected?.length || !usable?.length) return [0, 0];
  const startTimestamp = selected[0]?.timestamp;
  const endTimestamp = selected[selected.length - 1]?.timestamp;
  const usableTimestamps = usable.map((u) => u?.timestamp);
  if (!endTimestamp) return [startTimestamp, startTimestamp];
  const startIndex = usable.findIndex(
    (f) => f?.timestamp === findClosestNumInArray(startTimestamp, usableTimestamps),
  );
  const endIndex = usable.findIndex(
    (f) => f?.timestamp === findClosestNumInArray(endTimestamp, usableTimestamps),
  );
  if (endIndex - startIndex >= MAX_FRAMES_PER_LAYER)
    return [startIndex, startIndex + MAX_FRAMES_PER_LAYER];
  return [startIndex, endIndex];
}

function findClosestNumInArray(target: number, arr: number[]) {
  return arr.reduce((prev, curr) => {
    return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
  });
}

export function calculateCanvasSizeForViewport(
  viewportSize: [number, number],
  aspectRatio: [number, number] = [16, 9],
): [number, number] {
  const [vpHeight, vpWidth] = viewportSize;
  let canvasHeight = vpHeight;
  const ratio = aspectRatio[0] / aspectRatio[1];
  while (canvasHeight * ratio > vpWidth) {
    canvasHeight--;
  }
  return [canvasHeight, canvasHeight * ratio];
}

export function hasAnyWdLayer(wdSpaces: WeatherDataSpaceDef[]) {
  if (!wdSpaces?.length) return false;
  const wdSpace = wdSpaces[0];
  const layersNum =
    (wdSpace.gribMapLayers?.length || 0) +
    (wdSpace.radarMapLayers?.length || 0) +
    (wdSpace.satelliteMapLayers?.length || 0);
  return layersNum > 0;
}

export function isZoomInteraction(interaction: Interaction) {
  return (
    interaction instanceof DoubleClickZoom ||
    interaction instanceof PinchZoom ||
    interaction instanceof KeyboardZoom ||
    interaction instanceof MouseWheelZoom ||
    interaction instanceof DragZoom
  );
}

export async function takeCanvasScreenshot(
  project: C9ProjectDef,
  activeScene: string,
  aspectRatio: [number, number],
  activeTime: number,
  activeFramerate: 25 | 30 | 50 | 60,
) {
  const req = new SnapshotRequestDTO();
  req.projectDef = project;
  req.sceneDefId = activeScene;
  req.thumbnailHeight = STUDIO_PANEL_HEIGHT;
  req.thumbnailWidth = STUDIO_PANEL_HEIGHT * (aspectRatio[0] / aspectRatio[1]);
  req.frameNumber = getFrameForMS(activeTime, activeFramerate);
  try {
    return await getSnapshot(req);
  } catch (err) {
    console.error('Take screenshot err, ', err);
    return NO_THUMBNAIL_URL;
  }
}

export function awaitSyncFunctionExecution(fn: Function, ...args: any[]): Promise<void> {
  return new Promise((resolve) => {
    fn(...args);
    resolve();
  });
}

export async function precacheDrawings(scenes: SceneDef[]) {
  for (const sc of scenes) {
    for (const map of sc.mapPanels) {
      for (const dr of map.drawingElements) {
        const resolution = zoomToResolution(map.mapPositionControl.zoom);
        const features = new GeoJSON().readFeatures(dr.drawingGeoJson);
        for (const feat of features) {
          const props = feat.getProperties();
          if (props.drawingType == 'Front')
            await awaitSyncFunctionExecution(styleForFronts, feat, resolution, false, dr.id, false);
        }
      }
    }
  }
}

function interpolateCoordinatesForMultiLine(
  // geojson: any,
  coords: [[number, number], [number, number]],
  direction: 'horizontal' | 'vertical',
  INTER_SEQ_METERS = 1000,
) {
  const [[startLon, startLat], [endLon, endLat]] = coords;
  const returnCoords = [[startLon, startLat]];
  if (direction === 'horizontal') {
    for (let start = startLon; start <= endLon; start += INTER_SEQ_METERS) {
      returnCoords.push([start + INTER_SEQ_METERS, startLat]);
    }
    returnCoords.push([endLon, endLat]);
  }
  if (direction === 'vertical') {
    for (let start = startLat; start <= endLat; start += INTER_SEQ_METERS) {
      returnCoords.push([startLon, start + INTER_SEQ_METERS]);
    }

    returnCoords.push([endLon, endLat]);
  }
  return returnCoords;
}

function getGraticulesCoords(
  longitudeInterval: number,
  latitudeInterval: number,
  mapBounds: [number, number, number, number],
) {
  const coords: { lons: number[]; lats: number[] } = { lons: [], lats: [] };
  const [left, top, right, bottom] = mapBounds;
  for (let i = -180; i <= 180; i += longitudeInterval) {
    coords.lons.push(i);
  }
  for (let i = -90; i <= 90; i += latitudeInterval) {
    coords.lats.push(i);
  }
  coords.lons = coords.lons.filter((l) => l >= left && l <= right);
  coords.lats = coords.lats.filter((l) => l >= bottom && l <= top);
  return coords;
}

function calculateRotationAndOffset(feature: FeatureLike, mapSize: number[], isMerc: boolean) {
  const direction = feature.get('direction');
  const rotation = direction === 'vertical' ? Math.PI / 2 : 0;
  const offsetCorrection = isMerc ? 0 : direction === 'vertical' ? 5 : -5;
  const offsetX = (direction === 'vertical' ? -mapSize[1] : mapSize[0]) / 2 + offsetCorrection;
  return { offsetX, rotation };
}

export function handleGraticules(map: OlMap | null, mapDef: MapPanelDef, onDrag?: boolean) {
  // @ts-ignore
  if (!handleGraticules.cache) {
    // @ts-ignore
    handleGraticules.cache = {};
  }

  if (!map) return;
  const mapSize = map.getSize()!;
  const proj = map.getView().getProjection().getCode();
  const isGVNSP = proj.includes('ESRI:54049');
  const isMerc = proj.includes('EPSG:3857');
  const existingLayer = getLayerByClassName('graticules-layer', map);
  const existingGvnspIndicatorLayer = getLayerByClassName('gvnsp-indicators-layer', map);
  if (!mapDef?.graticule?.enabled) {
    // @ts-ignore
    handleGraticules.cache[mapDef.id] = undefined;

    if (existingLayer) {
      map.removeLayer(existingLayer);
      existingLayer.dispose();
    }
    return;
  }
  let cachedArgs;
  if (!onDrag || isGVNSP) {
    // @ts-ignore
    cachedArgs = handleGraticules.cache[mapDef.id];
  }
  const { latitudeInterval, longitudeInterval, strokeColor, strokeWidth, zindex, enableLabels } =
    mapDef.graticule;
  const updatedStrokeColor = singleColorOpacity(strokeColor);
  if (cachedArgs) {
    if (
      cachedArgs.latitudeInterval == latitudeInterval &&
      cachedArgs.longitudeInterval == longitudeInterval &&
      cachedArgs.strokeColor == updatedStrokeColor &&
      cachedArgs.strokeWidth == strokeWidth &&
      cachedArgs.zindex == zindex &&
      cachedArgs.enableLabels === enableLabels
    )
      return;
    if (
      cachedArgs.latitudeInterval == latitudeInterval &&
      cachedArgs.longitudeInterval == longitudeInterval &&
      cachedArgs.zindex == zindex &&
      existingLayer
    ) {
      (existingLayer?.getSource() as VectorSource)?.forEachFeature((f) => {
        const { offsetX, rotation } = calculateRotationAndOffset(f, mapSize, isMerc);
        f.setStyle(
          new Style({
            stroke: new Stroke({
              color: updatedStrokeColor,
              width: strokeWidth,
            }),
            text: new Text({
              text: isGVNSP || !enableLabels ? null : f.get('text'),
              font: '20px Arial',
              fill: new Fill({ color: updatedStrokeColor }),
              textBaseline: 'bottom',
              textAlign: rotation ? 'left' : 'right',
              rotation: rotation ? -rotation : 0,
              offsetX: offsetX,
            }),
          }),
        );
      });
      if (existingGvnspIndicatorLayer) {
        (existingGvnspIndicatorLayer?.getSource() as VectorSource)?.forEachFeature((f) => {
          const isHorizonthal = f.get('direction') === 'vertical';
          f.setStyle(
            new Style({
              text: new Text({
                text: isHorizonthal || !enableLabels ? null : f.get('text'),
                font: '20px Arial',
                fill: new Fill({ color: updatedStrokeColor }),
                // textBaseline: 'bottom',
                // textAlign: 'left',
                // rotation: rotation ? -rotation : 0,
                // offsetX: offsetX,
              }),
            }),
          );
        });
      }
      // @ts-ignore
      handleGraticules.cache[mapDef.id] = {
        // @ts-ignore
        ...handleGraticules.cache[mapDef.id],
        strokeColor: updatedStrokeColor,
        strokeWidth,
        enableLabels,
      };
      return;
    }
  }

  if (existingLayer) {
    map.removeLayer(existingLayer);
    existingLayer.dispose();
  }
  if (existingGvnspIndicatorLayer) {
    map.removeLayer(existingGvnspIndicatorLayer);
    existingGvnspIndicatorLayer.dispose();
  }
  // const features: Feature[] = [];
  const transformedRawExt = transformExtent(
    map.getView().calculateExtent(map.getSize()),
    proj,
    'EPSG:4326',
  );
  const [left, top, right, bottom] = !isMerc
    ? mapDef.baseMapSetup.baseMapConfigurationBounds
    : transformedRawExt;
  const { lats, lons } = getGraticulesCoords(
    longitudeInterval,
    latitudeInterval,
    mapDef.baseMapSetup.baseMapConfigurationBounds,
  );
  const geojson: any = {
    type: 'FeatureCollection',
    features: [],
  };

  const gvnspPointsJson: any = {
    type: 'FeatureCollection',
    features: [],
  };

  for (const lat of lats) {
    let lineCoords = [fromLonLat([left, lat]), fromLonLat([right, lat])]; // Line from left to right
    if (!isMerc) {
      lineCoords = interpolateCoordinatesForMultiLine(
        // @ts-ignore
        lineCoords,
        'horizontal',
        isGVNSP ? 100000 : 1000,
      );
    }

    if (isGVNSP) {
      const { projectionCenterLat, projectionCenterLon } = mapDef.properties;

      lineCoords = lineCoords.filter((c) => {
        const [lon, lat] = toLonLat(c, 'EPSG:3857');
        const dist = distance(lat, lon, Number(projectionCenterLat), Number(projectionCenterLon));
        return dist <= 5200;
      });
    }

    const lastPoint = lineCoords[lineCoords.length - 1];

    geojson.features.push({
      type: 'Feature',
      properties: {
        text: Number(lat) > 0 ? lat.toFixed(0) + '°N' : Math.abs(Number(lat)).toFixed(0) + '°S',
        direction: 'horizontal',
      },
      geometry: {
        type: 'LineString',
        coordinates: lineCoords,
      },
    });
    if (lastPoint && isGVNSP)
      gvnspPointsJson.features.push({
        type: 'Feature',
        properties: {
          text: Number(lat) > 0 ? lat.toFixed(0) + '°N' : Math.abs(Number(lat)).toFixed(0) + '°S',
          direction: 'horizontal',
        },
        geometry: {
          type: 'Point',
          coordinates: lastPoint,
        },
      });
  }
  for (const lon of lons) {
    let lineCoords = [fromLonLat([lon, bottom]), fromLonLat([lon, top])]; // Line from bottom to top
    if (!isMerc) {
      lineCoords = interpolateCoordinatesForMultiLine(
        // @ts-ignore
        lineCoords,
        'vertical',
        isGVNSP ? 100000 : 1000,
      );
    }

    if (isGVNSP) {
      const { projectionCenterLat, projectionCenterLon } = mapDef.properties;
      lineCoords = lineCoords.filter((c) => {
        const [lon, lat] = toLonLat(c, 'EPSG:3857');
        const dist = distance(lat, lon, Number(projectionCenterLat), Number(projectionCenterLon));
        return dist <= 6371;
      });
    }

    const lastPoint = lineCoords[lineCoords.length - 1];
    geojson.features.push({
      type: 'Feature',
      properties: {
        text: Number(lon) > 0 ? lon.toFixed(0) + '°E' : Math.abs(Number(lon)).toFixed(0) + '°W',
        direction: 'vertical',
      },
      geometry: {
        type: 'LineString',
        coordinates: lineCoords,
      },
    });
    if (lastPoint && isGVNSP) {
      gvnspPointsJson.features.push({
        type: 'Feature',
        properties: {
          text: Number(lon) > 0 ? lon.toFixed(0) + '°E' : Math.abs(Number(lon)).toFixed(0) + '°W',
          direction: 'vertical',
        },
        geometry: {
          type: 'Point',
          coordinates: lastPoint,
        },
      });
    }
  }

  // if (isGVNSP) {
  //   const { projectionCenterLat, projectionCenterLon } = mapDef.properties;

  //   for (let i = 0; i < geojson.features.length; i++) {
  //     let k = 0;
  //     while (k < geojson.features[i].geometry.coordinates.length) {
  //       const [lon, lat] = toLonLat(geojson.features[i].geometry.coordinates[k], 'EPSG:3857');

  //       const dist = distance(lat, lon, Number(projectionCenterLat), Number(projectionCenterLon));

  //       if (dist > 6371) {
  //         // 6371 km is earth radius, if the coordinate is more than R away, remove it
  //         geojson.features[i].geometry.coordinates.splice(k, 1);
  //       } else {
  //         k++;
  //       }
  //     }
  //   }
  // }

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojson, {
      featureProjection: map.getView().getProjection(),
      dataProjection: 'EPSG:3857',
    }),
  });

  const vectorLayer = new VectorImageLayer({
    source: vectorSource,
    className: 'graticules-layer',
    zIndex: zindex,
    style: function (feature) {
      if (isGVNSP) {
        /**Only for gvnsp no text */

        return new Style({
          stroke: new Stroke({
            color: updatedStrokeColor,
            width: strokeWidth,
          }),
        });
      } else {
        const { offsetX, rotation } = calculateRotationAndOffset(feature, mapSize, isMerc);
        return new Style({
          stroke: new Stroke({
            color: updatedStrokeColor,
            width: strokeWidth,
          }),
          text: new Text({
            text: !enableLabels ? null : feature.get('text'),
            font: '20px Arial',
            fill: new Fill({ color: updatedStrokeColor }),
            textBaseline: 'bottom',
            textAlign: rotation ? 'left' : 'right',
            rotation: rotation ? -rotation : 0,
            offsetX: offsetX,
          }),
        });
      }
    },
  });
  if (isGVNSP) {
    const gvnspIndicatorsSource = new VectorSource({
      features: new GeoJSON().readFeatures(gvnspPointsJson, {
        featureProjection: map.getView().getProjection(),
        dataProjection: 'EPSG:3857',
      }),
    });
    const gvnspIndicatorsLayer = new VectorImageLayer({
      source: gvnspIndicatorsSource,
      className: 'gvnsp-indicators-layer',
      zIndex: GVNSP_MASK_ZINDEX + 1,
      style: function (feature) {
        const isHorizonthal = feature.get('direction') === 'vertical';
        return new Style({
          // image: new CircleStyle({
          //   // This is to visualize the point if it's too small or missing
          //   radius: 0,
          //   fill: new Fill({ color: 'red' }),
          //   stroke: new Stroke({
          //     color: 'white',
          //     width: 1.5,
          //   }),
          // }),
          text: new Text({
            text: isHorizonthal || !enableLabels ? null : feature.get('text'),
            font: '20px Arial',
            fill: new Fill({ color: updatedStrokeColor }),
            // textBaseline: 'bottom',
            // textAlign: 'left',
            // rotation: rotation ? -rotation : 0,
            // offsetX: offsetX,
          }),
        });
      },
    });
    map.addLayer(gvnspIndicatorsLayer);
  }
  // @ts-ignore
  handleGraticules.cache[mapDef.id] = {
    latitudeInterval,
    longitudeInterval,
    strokeColor: updatedStrokeColor,
    strokeWidth,
    zindex,
  };
  map.addLayer(vectorLayer);
}
interface GeoJSONPoint {
  type: 'Point';
  coordinates: [number, number];
}

interface GeoJSONGeometryCollection {
  type: 'GeometryCollection';
  geometries: GeoJSONGeometry[];
}

type GeoJSONGeometry = GeoJSONPoint | GeoJSONGeometryCollection;

export function recalculateGeoJSON(coordinates: [number, number][], newPoint: [number, number]) {
  const minX = Math.min(...coordinates.map((coord) => coord[0]));
  const minY = Math.min(...coordinates.map((coord) => coord[1]));
  const deltaX = newPoint[0] - minX;
  const deltaY = newPoint[1] - minY;

  return coordinates.map((coord) => [coord[0] + deltaX, coord[1] + deltaY]);
}

export function drawCircleGradient(params: {
  coordinates: Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][];
  state: State;
  fromColor: string;
  toColor: string;
  width?: number;
}) {
  // @ts-ignore
  const [[x, y], [x1, y1]] = params.coordinates;
  const ctx = params.state.context;
  const dx = x1 - x;
  const dy = y1 - y;
  const radius = Math.sqrt(dx * dx + dy * dy);

  const ringWidth = params.width && params.width >= 30 ? params.width : 30;

  const innerRadius = radius - 12;
  const outerRadius = radius + (ringWidth - 12);

  ctx.beginPath();

  ctx.arc(x, y, innerRadius, 0, 2 * Math.PI, true);
  ctx.arc(x, y, outerRadius, 0, 2 * Math.PI, false);
  const gradient = ctx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
  // gradient.addColorStop(innerRadius / outerRadius, 'transparent');
  gradient.addColorStop(0, params.fromColor);
  gradient.addColorStop(1, params.toColor);

  // ctx.strokeStyle = gradient;
  // ctx.lineWidth = 900;
  // ctx.stroke();
  ctx.fillStyle = gradient;
  ctx.fill();
}

export function findClosestPoints(points1: Coordinate[], points2: Coordinate[]) {
  return points1.map((point1) => {
    let minDistance = Infinity;
    let closestPoint: Coordinate | null = null;

    points2.forEach((point2) => {
      const dist = turfDistance(point(point1), point(point2), { units: 'meters' });
      if (dist < minDistance) {
        minDistance = dist;
        closestPoint = point2;
      }
    });

    return closestPoint as unknown as Coordinate[];
  });
}
